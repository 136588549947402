import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../Icon";
import Breadcrumbs from "../Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";

let navigation1 = [
  {
    id: 1,
    title: "Profile",
    icon: "user",
    url: "/profile-info",
  },
  {
    id: 2,
    title: "Bank Verification",
    icon: "wallet",
    url: "/bank-verification",
    separator: true,
  },
  {
    id: 3,
    title: "My Referrals",
    icon: "share",
    url: "/my-referral",
  },
  // {
  //   title: "2FA",
  //   icon: "barcode",
  //   url: "/2fa",
  // },
  {
    id: 4,
    title: "Change password",
    icon: "lock",
    url: "/change-password",
  },

  {
    id: 5,
    title: "KYC Verification ",
    icon: "eye",
    url: "/user-kyc-verification",
    // separator: true,
  },
  {
    id: 6,
    title: "Resident Verification ",
    icon: "eye",
    url: "/resident-verification",
    // separator: true,
  },
  {
    id: 7,
    title: "Coupon and Rewards",
    icon: "share",
    url: "/coupon-and-rewards",
    // separator: true,
  },
];

const Proflie = ({ title, breadcrumbs, children }) => {

  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const dispatch = useDispatch()

  // const [data, setData] = useState()
  // useEffect(() => {
  //   if (userStatus.length === 0) {
  //     dispatch(userStatusCreator())
  //   }

  //   if (userStatus?.residence_verification && userStatus?.residence_verification !== 4) {
  //     let data = navigation1.filter(x => x.id !== 6)
  //     setNavigation(data)
  //   } else {
  //     setNavigation(navigation1)
  //   }

  //   if (userStatus?.role !== process.env.REACT_APP_VALID_ROLE) {
  //     let data = navigation1.filter(x => (x.title !== "My Referrals" && x.id !== 6))
  //     setNavigation(data)
  //   }
  //   else if (userStatus?.residence_verification && userStatus?.residence_verification !== 4) {
  //     let data = navigation1.filter(x => x.id !== 6)
  //     setNavigation(data)
  //   }
  // }, [userStatus])

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userStatusCreator());
    }

    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      let data = navigation1.filter(x => (x.title !== "KYC Verification ") && (x.title !== "Resident Verification "))
      setNavigation(data);
    }
    else if (userStatus?.role === process.env.REACT_APP_VALID_ROLE) {
      if (userStatus && (userStatus?.residence_verification !== parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_CANCELLED))) {
        let data = navigation1.filter(x => (x.id !== 6));
        setNavigation(data)
      }
      else {
        let data = navigation1.map((x) => { return x });
        setNavigation(data);
      }
    }
  }, [userStatus]);

  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);
  const [navigation, setNavigation] = useState()
  const activeLink = navigation?.find((x) => pathname.includes(x.url));

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn("container", styles.container)}>
          <h2 className={cn("h2", styles.title)}>{title}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.sidebar}>
            <div className={cn(styles.dropdown, { [styles.active]: visible })}>
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                <Icon name={activeLink?.icon} size="24" />
                {activeLink?.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  {navigation?.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      key={index}
                      to={item.url}
                      exact
                      onClick={() => setVisible(false)}
                    >
                      <Icon name={item.icon} size="24" />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Proflie;
