import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WalletOverviewDetails.module.sass";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Wallet from "../../components/Wallet";
import Icon from "../../components/Icon";
import Faq from "./Faq";
import Modal from "../../components/Modal";
import Deposit from "../../components/Deposit";
import Withdraw from "../../components/Withdraw";
import Transfer from "../../components/Transfer";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../components/Notification";
import { getCoinsCreator } from "../../actions/getCoins";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import LoaderScreen from '../../components/LoaderScreen'

const WalletOverviewDetails = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleDeposit, setVisibleDeposit] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(true);
  const [walletAddressData, setWalletAddressData] = useState([])
  const dispatch = useDispatch();
  const [depositLoadting, setDepositLoading] = useState(false)
  const { state, imageUrl } = useLocation();
  const [newCoinGeneratedID, setNewCoinGeneratedId] = useState()
  const [loading, setLoading] = useState(false)

  const { coinsData } = useSelector((state) => { return state.coins })
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCoinsCreator());
    getWalletAddressCreator()
  }, []);

  //function for getting address data
  const getWalletAddressCreator = async () => {
    setLoading(true)
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        coinId: state.coinId
      };
      const payload = await requestHandler("getAddress", "post", data, "jwt_token");
      setLoading(false)
      setWalletAddressData(payload.data.data)
    }
    catch (e) {
      setLoading(false)
    };
  };


  const newWithdrawCreator = async (data) => {
    try {
      setLoading(true)
      const payload = await requestHandler("newWithdrawCrypto", "post", data, "jwt_token", "file");
      setLoading(false)
      if (payload.status === 201) {
        setNewCoinGeneratedId(payload?.data?.message?.data.id)
        setVisibleSuccessfully(false);
        setActiveIndex(1);
      }

    }
    catch (e) {
      setLoading(false)
    };
  };

  const verifyOtpWithdrawCreator = async (data) => {
    try {
      setLoading(true)
      const payload = await requestHandler("verifyWithdrawCrypto", "post", data, "jwt_token");
      setLoading(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        setActiveIndex(2);
      };
    }
    catch (e) {
      setLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };


  const cryptoAddressGenerater = async (coinId) => {
    setDepositLoading(true)
    const data = {
      coinId: coinId,
      signature: localStorage.getItem("signature"),
    }
    try {
      const payload = await requestHandler("generateCryptoAddress", "post", data, "jwt_token");
      setDepositLoading(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        navigate('/wallet-overview')
      };
    }
    catch (e) {
      setDepositLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const codeScreenHandler = (firstField, secondField, thirdField, fourthField, fifthField, sixthField, verificationType) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    let data = {
      code: code,
      id: newCoinGeneratedID,
      verification_type: verificationType,
      signature: localStorage.getItem("signature"),
    };
    verifyOtpWithdrawCreator(data)
  };

  const withdrawNewAddressHandler = (coin, withdrawAddress, ownership, addressProof, enteredAmount, token) => {
    let data = {
      signature: localStorage.getItem("signature"),
      amount: enteredAmount,
      coinId: coin,
      ownership: ownership,
      scanOwnership: addressProof,
      network: token ? token : null,
      address: withdrawAddress,
    };

    newWithdrawCreator(data);
  };

  const withdrawAddressHandler = (coinId, amount, address) => {
    let data = {
      coinId: coinId,
      amount: amount,
      address_id: address,
      signature: localStorage.getItem("signature"),
    };
    newWithdrawCreator(data)
  };

  return (
    <>
      <Wallet userStatus={userStatus}>
        <div className={styles.top}>
          <div className={styles.line}>
            <h4 className={cn("h4", styles.title)}>
              <Link className={styles.back} to="/wallet-overview">
                <Icon name="arrow-left" size="32" />
              </Link>
              {state.symbol.toUpperCase()}
              <span>{state.name.toUpperCase()}</span>
            </h4>
            <div className={styles.btns}>
              {
                state.stableCoin && state.stableCoin === 1 ?
                  <>
                    {
                      state.is_eur_market && parseInt(state.is_eur_market) === 2 ?
                        <>
                          <Link
                            className={cn("button-small", styles.button)}
                            to="/deposit-fiat"
                          >
                            Deposit {state.symbol.toUpperCase()}
                          </Link>
                          <Link
                            className={cn("button-stroke button-small", styles.button)}
                            to="/withdraw-fiat"
                          >
                            Withdraw {state.symbol.toUpperCase()}
                          </Link>
                        </>
                        :
                        <>
                          <button
                            className={cn("button-small", styles.button)}
                            onClick={() => setVisibleDeposit(true)}
                          >
                            Deposit {state.symbol.toUpperCase()}
                          </button>

                          <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setVisibleWithdraw(true)}
                          >
                            Withdraw {state.symbol.toUpperCase()}
                          </button>
                        </>
                    }
                  </>
                  :
                  <>
                    <button
                      className={cn("button-small", styles.button)}
                      onClick={() => setVisibleDeposit(true)}
                    // disabled={true}
                    >
                      Deposit {state.symbol.toUpperCase()}
                    </button>

                    <button
                      className={cn("button-stroke button-small", styles.button)}
                      onClick={() => setVisibleWithdraw(true)}
                    >
                      Withdraw {state.symbol.toUpperCase()}
                    </button>
                  </>
              }</div>
          </div>
          <div className={styles.details}>
            <div className={styles.info}>Total balance</div>
            <div className={styles.currency}>
              <div className={styles.number}>{state.balance}</div>
              <div className={cn("category-green", styles.category)}>{state.symbol.toUpperCase()}</div>
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>FAQ</div>
            <div className={styles.body}>
              <Faq />
            </div>
          </div>
        </div>
      </Wallet>
      <Modal visible={visibleDeposit} onClose={() => setVisibleDeposit(false)}>
        <Deposit state={state} imageUrl={imageUrl}
          userStatus={userStatus}
          cryptoAddressGenerater={cryptoAddressGenerater}
          depositLoadting={depositLoadting} />
      </Modal>
      <Modal
        visible={visibleWithdraw}
        onClose={() => { setVisibleWithdraw(false); setActiveIndex(0) }
        }>
        <Withdraw
          activeIndex={activeIndex}
          visibleSuccessfully={visibleSuccessfully}
          codeScreenHandler={codeScreenHandler}
          state={state}
          walletAddressData={walletAddressData}
          withdrawAddressHandler={withdrawAddressHandler}
          coinsData={coinsData}
          coinOptions={coinsData?.data?.data}
          withdrawNewAddressHandler={withdrawNewAddressHandler}
          setVisibleSuccessfully={setVisibleSuccessfully}
          userStatus={userStatus}
        />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)
        }>
        <Transfer />
      </Modal>
      {loading && <LoaderScreen />}
    </>
  );
};

export default WalletOverviewDetails;
