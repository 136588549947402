import { combineReducers } from "redux";
import exchangeReducer from './exchangeReducer'
import getCoinsReducer from './getCoinsReducer';
import newOrderReducer from './newOrderReducer';
import depositFiatVerifyPaymentReducer from './depositFiatVerifyPaymentReducer';
import getUserStatusReducer from './getUserStatusReducer';
const appReducer= combineReducers({
    exchange: exchangeReducer,
    coins: getCoinsReducer,
    newOrder: newOrderReducer,
    depositFiatVerifyPayment: depositFiatVerifyPaymentReducer,
    getUserStatus: getUserStatusReducer,
});

const rootReducer = (state, action) => {
    if (action.type == 'CLEAR_STATE') {
      state = undefined;
    }
    return appReducer(state, action);
  };
  export default rootReducer;