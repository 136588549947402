import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Item from "./Item";
import NoDataFound from "../../../components/NoDataFound";

const Funds = ({ cashbackData }) => {

  return (
    <div className={styles.wrap}>
      <h1 className={cn("h2", styles.title)}>
        Cashback Criteria
      </h1>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Amount(Fiat)</div>
          <div className={styles.col}>Min Deposit(Fiat)</div>
          <div className={styles.col}>Validity(After activation)</div>
        </div>
        {
          cashbackData?.length > 0 ?
            cashbackData?.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                key={index}
                index={index}
              />
            )) :
            <div className={styles.btns}>
              <NoDataFound className={styles.customButton} />
            </div>}
      </div>
    </div>
  );
};

export default Funds;
