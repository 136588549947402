import cn from "classnames";
import styles from "./Learn.module.sass";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Play from "../../../components/Play";
import { memo } from "react";

const items = [
  {
    title: "Simple and Easy investing and trading",
    content: "We want to make simple and easily investing and trading accessible to everyone. Suitable for beginners and advance traders and investors. Take charge of your own financial future.",
    image: "/images/content/learn-pic-1.jpg",
    image2x: "/images/content/learn-pic-1@2x.jpg",
    url: "/",
  },
  {
    title: "Low Fees, no complicated Taker or Maker fees",
    content: "We aim to apply the lowest trading fee for all traders and investors no matter what your trading volume is. Everyone should benefit from our Low Fees.",
    //date: "Jun 1, 2021",
    image: "/images/content/learn-pic-2.jpg",
    image2x: "/images/content/learn-pic-2@2x.jpg",
    url: "/",
  },
  {
    title: "Instant Euro Deposits",
    content: "Deposit your Euro to buy any available cryptocurrency instantly via iDeal & SEPA.",
    //date: "Jun 1, 2021",
    image: "/images/content/learn-pic-4.jpg",
    image2x: "/images/content/learn-pic-4@2x.jpg",
    url: "/",
  },
  {
    title: "Buy and Sell cryptocurrencies on the go",
    content: "Trade whenever, wherever and always online.",
    //date: "Jun 1, 2021",
    image: "/images/content/learn-pic-3.jpg",
    image2x: "/images/content/learn-pic-3@2x.jpg",
    url: "/",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Learn = ({ scrollToRef }) => {
  // const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 100000,
        settings: "unslick",
      },
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.wrap}>
            <h2 className={cn("h3", styles.title)}>Invest with <span>Low Trading Fees</span></h2>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className={cn("learn-slider", styles.slider)} {...settings}>
            {items?.map((x, index) =>
              index < 1 ? (
                <div className={styles.item} key={index}>
                  <div className={styles.preview}>
                    <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Card" />
                    {x.play && <Play small />}
                  </div>
                  <div className={styles.line}>
                    <div className={styles.wrap}>
                      <div className={styles.subtitle}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.item} to={x.url} key={index}>
                  <div className={styles.preview}>
                    <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Card" />
                    {x.play && <Play small />}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.subtitle}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                    {x.date && <div className={styles.date}>{x.date}</div>}
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default memo(Learn);
