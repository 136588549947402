import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./FiatDepositList.module.sass";
import Wallet from "../../components/Wallet";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const FiatDepositList = () => {
    const [depositListData, setDepositListData] = useState([]);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDepositFiatList(pageNo);
    }, [pageNo]);

    const handleLoadMore = () => {
        setPageNo(pageNo + 1);
    };

    const getDepositFiatList = async (page) => {
        setLoading(true);
        try {
            let data = {
                signature: localStorage.getItem("signature"),
                page: page,
            }
            const payload = await requestHandler("get_deposit_fiat_list", "post", data, "jwt_token");
            setLoading(false);
            setDepositListData(payload?.data?.data);
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "danger"
            });
        }
    };

    const [visibleWithdraw, setVisibleWithdraw] = useState(false);
    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                depositListData={depositListData}
                                pageNo={pageNo}
                                handleLoadMore={handleLoadMore}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
            <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw />
            </Modal>
        </>
    );
};


export default FiatDepositList;