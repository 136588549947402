import React, { useEffect, useRef, useState } from "react";
import styles from "./ProfileInfo.module.sass";
import Profile from "../../components/Profile";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import cn from "classnames";
import TextInput from "../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Icon from "../../components/Icon";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Profile info",
  },
];

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const [fields, setFields] = useState(false);
  const [countryCode, setCountryCode] = useState(userStatus?.country_code ? userStatus?.country_code : "");
  const [phoneNumber, setPhoneNumber] = useState(userStatus?.mobile_no ? userStatus?.mobile_no : "");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [buttonText, setButtonText] = useState("");
  const inputRef = useRef(null);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (userStatus && Object.keys(userStatus).length === 0) {
      dispatch(userStatusCreator());
    }
  }, []);

  const updateMobileNumberHandler = async (country_code, mobile_no) => {
    setLoading(true);
    let dummyNumber = mobile_no;
    let finalNumber = dummyNumber?.slice(countryCode?.length);
    let data = {
      country_code: country_code,
      mobile_no: finalNumber,
      signature: localStorage.getItem("signature"),
    };
    try {
      const updateMobileNumberPayload = await requestHandler("update_profile", "post", data, "jwt_token");
      setLoading(false);
      if (updateMobileNumberPayload && updateMobileNumberPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateMobileNumberPayload?.data?.message[0].msg,
          type: "success"
        });
      }
      dispatch(userStatusCreator());
    }
    catch (e) {
      addNotification({
        title: 'Alert',
        message: 'Please enter your phone number',
        type: 'danger'
      })
      setLoading(false);
    }
  };

  return (
    <Profile title="Profile info" breadcrumbs={breadcrumbs}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={styles.details}>
          <div className={styles.user}>{`Name: ${userStatus?.fname === null || userStatus?.fname === undefined ? "______" : userStatus?.fname}`}</div>
          <div className={styles.email}>{userStatus?.email === null || userStatus?.email === undefined ? "______" : userStatus?.email}</div>
          <div className={styles.level}>{`Mobile: (${userStatus?.country_code ? userStatus?.country_code : "___"})${userStatus?.mobile_no === null || userStatus?.mobile_no === undefined ? "______" : userStatus?.mobile_no}`}</div>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              setFields(!fields);
              if (e.target.innerHTML === "Save Changes") {
                if (countryCode !== null && countryCode !== "" && phoneNumber !== null && phoneNumber !== "") {
                  updateMobileNumberHandler(
                    countryCode ? countryCode : userStatus?.country_code,
                    phoneNumber ? phoneNumber : userStatus?.mobile_no
                  );
                }
                else {
                  addNotification({
                    title: 'Alert',
                    message: 'Please enter your phone number',
                    type: 'danger'
                  })
                }
              }
            }}
          >
            {fields ? "Save Changes" : "Edit"}
          </button>

          {fields &&
            <div className={styles.box}>
              <PhoneInput
                ref={inputRef}
                country={'us'}
                value={phoneNumber}
                name="phone"
                countryCode
                onChange={(phone, country) => {
                  setPhoneNumber(phone)
                  setCountryCode(country.dialCode);
                }}
              />
            </div>
          }
        </div>
        <div id="block_container">
          <div className={styles.level}>{`Customer Id: ${userStatus?.uid === null || userStatus?.uid === undefined ? "______" : userStatus?.uid}`}</div>
        </div>
      </div>
      <div className={styles.settings}>
        <div className={styles.settings}>
          <div className={styles.title}>General</div>
          <div className={styles.box} >
            <div className={styles.subtitle}>Details</div>
            <div className={styles.item} >
              <div className={styles.category}>User Level</div>
              {(userStatus?.user_level === undefined || userStatus.user_level === null) ? "---" : (<div className={styles.content}>{userStatus.user_level}</div>)}
            </div>
            {userStatus?.role === process.env.REACT_APP_VALID_ROLE &&
              <div className={styles.item} >
                <div className={styles.category}>KYC Verification</div>
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) && (<div className={styles.content}>Pending</div>)}
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_USER_EXIT) && (<div className={styles.content}>User Exit</div>)}
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_UNDER_VERIFICATION) && (<div className={styles.content}>In process</div>)}
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) && (<div className={styles.content}>Cancelled</div>)}
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) && (<div className={styles.content}>Complete</div>)}
                {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_INREVIEW) && (<div className={styles.content}>In process</div>)}
              </div>
            }
            <div className={styles.item} >
              <div className={styles.category}>Bank Verification</div>
              {userStatus?.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PENDING) && (<div className={styles.content}>Pending</div>)}
              {userStatus?.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS) && (<div className={styles.content}>In process</div>)}
              {userStatus?.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) && (<div className={styles.content}>Complete</div>)}
            </div>
            <div className={styles.item} >
              <div className={styles.category}>Bank deposit</div>
              {userStatus?.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) ? (<div className={styles.content}>Enable</div>) : (<div className={styles.content}>Pending</div>)}
            </div>
          </div>
          <div className={styles.box} >
            <div className={styles.subtitle}>Advance Settings</div>
            <div className={styles.item} >
              <div className={styles.category}>Two Factor Authentication</div>
              {userStatus?.auth_enable === "Y" ? (<div className={styles.content}>Enable</div>) : (<div className={styles.content}>Disabled</div>)}
            </div>
          </div>
          {/* {userStatus.role === process.env.REACT_APP_VALID_ROLE && */}
          <div className={styles.box}>
            <div className={styles.subtitle}>Referral</div>
            <div className={styles.item} >
              <div className={styles.category}>Referral Code</div>
              {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ? "---" : <div className={styles.content}>{userStatus?.refferal_code}</div>}
              {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ?
                ""
                :
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(userStatus?.refferal_code).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <div className={styles.btnClass}>
                    <Icon name="copy" size="16" />
                  </div>
                </button>
              }
            </div>
            <div className={styles.item} >
              <div className={styles.category}>Referral Link</div>
              {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ? "---" : <div className={styles.content}>{`https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`}</div>}
              {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ?
                ""
                :
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(`https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <div className={styles.btnClass}>
                    <Icon name="copy" size="16" />
                  </div>
                </button>
              }
            </div>
            {/* <div className={styles.item} >
              <div className={styles.category}>Referral Link</div>
              <div className={styles.content}>{userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ? "---" : `https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`}
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(`https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <div className={styles.btnClass}>
                    <Icon name="copy" size="16" />
                  </div>
                </button>
              </div>
            </div> */}
          </div>
          {/* } */}
        </div>
      </div>
    </Profile>
  );
};

export default ProfileInfo;
