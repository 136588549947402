import React from "react";
import styles from "./Trade.module.sass";


const depositFees = [
  {
    name: "SEPA",
    aFee: `${process.env.REACT_APP_SEPA_FEE} `,
    rfee: `${process.env.REACT_APP_SEPA_R_FEE}`,
    image: "/images/content/fees-sepa.png",
    mAmount: `${process.env.REACT_APP_SEPA_MAX_AMOUNT}`,
    nAmount: `${process.env.REACT_APP_SEPA_MIN_AMOUNT}`
  },
  {
    name: "Ideal",
    aFee: `${process.env.REACT_APP_IDEAL_FEE} `,
    rfee: `${process.env.REACT_APP_IDEAL_R_FEE}`,
    image: "/images/content/ideal.svg",
    mAmount: `${process.env.REACT_APP_IDEAL_MAX_AMOUNT}`,
    nAmount: `${process.env.REACT_APP_IDEAL_MIN_AMOUNT}`
  },
  // {
  //   name: "Online Banking",
  //   aFee: `${process.env.REACT_APP_OTHER_BANK_FEE} `,
  //   rfee: `${process.env.REACT_APP_OTHER_BANK_R_FEE}`,
  //   image: "/images/content/online-banking.png",
  //   mAmount: `${process.env.REACT_APP_OTHER_BANK_MAX_AMOUNT}`,
  //   nAmount: `${process.env.REACT_APP_OTHER_BANK_MIN_AMOUNT}`
  // },
  // {
  //   name: "GiroPay",
  //   aFee: process.env.REACT_APP_GIROPAY_ABSOLUTE_FEE,
  //   rfee: process.env.REACT_APP_GIROPAY_RELATIVE_FEE,
  //   image: "/images/content/giropay.png",
  //   mAmount: process.env.REACT_APP_GIROPAY_MAX_AMOUNT,
  //   nAmount: process.env.REACT_APP_GIROPAY_MIN_AMOUNT
  // },
  // {
  //   name: "Sofort Banking",
  //   aFee: process.env.REACT_APP_SOFORT_BANKING_ABSOLUTE_FEE,
  //   rfee: process.env.REACT_APP_SOFORT_BANKING_RELATIVE_FEE,
  //   image: "/images/content/Sofort-Banking.png",
  //   mAmount: process.env.REACT_APP_SOFORT_BANKING_MAX_AMOUNT,
  //   nAmount: process.env.REACT_APP_SOFORT_BANKING_MIN_AMOUNT
  // },
  {
    name: "Bancontact",
    aFee: process.env.REACT_APP_BANCONTACT_ABSOLUTE_FEE,
    rfee: process.env.REACT_APP_BANCONTACT_RELATIVE_FEE,
    image: "/images/content/Bancontact.png",
    mAmount: process.env.REACT_APP_BANCONTACT_MAX_AMOUNT,
    nAmount: process.env.REACT_APP_BANCONTACT_MIN_AMOUNT
  },
  // {
  //   name: "PayPal",
  //   aFee: process.env.REACT_APP_PAYPAL_ABSOLUTE_FEE,
  //   rfee: process.env.REACT_APP_PAYPAL_RELATIVE_FEE,
  //   image: "/images/content/PayPal.png",
  //   mAmount: process.env.REACT_APP_PAYPAL_MAX_AMOUNT,
  //   nAmount: process.env.REACT_APP_PAYPAL_MIN_AMOUNT
  // },
  {
    name: "Przelewy24",
    aFee: process.env.REACT_APP_PRZELEWY24_ABSOLUTE_FEE,
    rfee: process.env.REACT_APP_PRZELEWY24_RELATIVE_FEE,
    image: "/images/content/Przelewy24.png",
    mAmount: process.env.REACT_APP_PRZELEWY24_MAX_AMOUNT,
    nAmount: process.env.REACT_APP_PRZELEWY24_MIN_AMOUNT
  },
  {
    name: "Online Uberweisen",
    aFee: process.env.REACT_APP_ONLINE_UBERWEISEN_ABSOLUTE_FEE,
    rfee: process.env.REACT_APP_ONLINE_UBERWEISEN_RELATIVE_FEE,
    image: "/images/content/Online-Uberweisen.png",
    mAmount: process.env.REACT_APP_ONLINE_UBERWEISEN_MAX_AMOUNT,
    nAmount: process.env.REACT_APP_ONLINE_UBERWEISEN_MIN_AMOUNT
  },
];

const bank = [
  {
    name: "Bank deposit",
    aFee: `${process.env.REACT_APP_BANK_WITHDRAWL_FEE} `,
    rfee: `${process.env.REACT_APP_BANK_WITHDRAWL_R_FEE}`,
    image: "/images/content/bank.png",
    mAmount: `${process.env.REACT_APP_BANK_WITHDRAWL_MAX_AMOUNT}`,
    nAmount: `${process.env.REACT_APP_BANK_WITHDRAWL_MIN_AMOUNT}`
  },
];

const Trade = () => {
  return (
    <div className={styles.trade}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Absolute Fees</div>
          <div className={styles.col}>Relative Fees</div>
          <div className={styles.col}>Max amount</div>
          <div className={styles.col}>Min amount</div>
        </div>
        <div style={{ padding: "10px" }}> Deposit fees </div>
        {depositFees.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Online" />
                </div>
                <div className={styles.label}>Name</div>
                {x.name}
              </div>
            </div>
            <div className={styles.col}>
              {x.aFee}
            </div>
            <div className={styles.col}>
              {x.rfee}
            </div>
            <div className={styles.col}>
              {x.mAmount}
            </div>
            <div className={styles.col}>
              {x.nAmount}
            </div>
          </div>
        ))}
        <div style={{ padding: "10px" }}> Withdrawal fees </div>
        {bank.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Coin" />
                </div>   {x.name}
              </div>
            </div>
            <div className={styles.col}>
              {x.aFee}
            </div>
            <div className={styles.col}>
              {x.rfee}
            </div>
            <div className={styles.col}>
              {x.mAmount}
            </div>
            <div className={styles.col}>
              {x.nAmount}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trade;
