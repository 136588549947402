import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal, twoDigitsAfterDecimal } from "../../../components/helper";

const Main = ({ currentMarketDetails, currentMarketPrice }) => {

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div className={styles.box}>
          <div className={styles.line}>
            <div className={styles.info}>{currentMarketDetails[0]?.slug.toUpperCase()}</div>
            <div className={cn("category-green", styles.category)}>   </div>
          </div>
          <div className={styles.content}>{currentMarketDetails[0]?.name.toUpperCase()}</div>
        </div>
        <div className={styles.box}>
          {/* <div className={styles.price}>{currentMarketDetails[0] ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) + ' ' + currentMarketDetails[0]?.currency.toUpperCase() : "---"}</div> */}
          <div className={styles.price}>{(currentMarketPrice?.currentMarketPrice === null || currentMarketPrice?.currentMarketPrice === undefined) ? currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) + " " + currentMarketDetails[0]?.currency.toUpperCase() : "" : currentMarketPrice?.currentMarketPrice + " " + currentMarketDetails[0]?.currency?.toUpperCase()}</div>
          {/* <div className={styles.content}>
            <Icon name="coin" size="16" /> {twoDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice,currentMarketDetails[0]?.slug.slice(0,3))}
          </div> */}
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.subtitle}>
            <Icon name="clock" size="16" />
            24h change
          </div>
          <div className={styles.content} style={{ color: parseFloat(currentMarketDetails[0]?.dayChange) > 0 ? '#00C076' : '#FF6838' }} >
            {currentMarketDetails ? parseFloat(currentMarketDetails[0]?.dayChange).toFixed(2) : "0.00"}%
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.subtitle}>
            <Icon name="clock" size="16" />
            Week Change
          </div>
          <div className={styles.content} style={{ color: parseFloat(currentMarketDetails[0]?.weekChange) > 0 ? '#00C076' : '#FF6838' }}>
            {parseFloat(currentMarketDetails[0]?.weekChange).toFixed(2)}%
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.subtitle}>
            <Icon name="chart" size="16" />
            Volume
          </div>
          <div className={styles.content}>
            {parseFloat(currentMarketDetails[0]?.volume).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
