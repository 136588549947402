import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import CanclePopup from "../Item/CancelPopup";
import Modal from "../../../../components/Modal";
import requestHandler from "../../../../actions/httpClient";
import { addNotification } from "../../../../components/Notification";
import Icon from "../../../../components/Icon";

const Item = ({ item, children, handleAddress, archiveAddressHandler }) => {
  const [visible, setVisible] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.icon}>
                <img src={item.icon} alt="Currency" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>{item.name.toUpperCase()}</div>
                <div className={styles.text}>{item.symbol.toUpperCase()}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info} title={item.address}>{item.address && item.address?.length > 35 ? item.address.substring(0, 34) + '...' : item.address + " "}
              <button className={styles.copy} onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(item.address).then(() => {
                  addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                })
              }}>
                <div className={styles.btnClass}>
                  <Icon name="copy" size="16" />
                </div>
              </button>
            </div>
            <div className={styles.text} title={item.ownership}>{item.ownership && item.ownership?.length > 35 ? item.ownership.substring(0, 34) + '...' : item.ownership}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {item.address_tag === "" || item.address_tag === undefined || item.address_tag === null ? "---" : item.address_tag}
            </div>
          </div>
          <div className={styles.col}>
            {item.status === parseInt(process.env.REACT_APP_STATUS_PENDING) && (<div className={cn("category-blue")}>Pending</div>)}
            {item.status === parseInt(process.env.REACT_APP_STATUS_PROCESSING) && (<div className={cn("category-blue")}>Processing</div>)}
            {item.status === parseInt(process.env.REACT_APP_STATUS_VERIFIED) && (<div className={cn("category-green")}>Verified</div>)}
            {item.status === parseInt(process.env.REACT_APP_STATUS_DECLINED) && (<div className={cn("category-red")}>Declined</div>)}
            {item.status === parseInt(process.env.REACT_APP_STATUS_CANCELLED) && (<div className={cn("category-red")}>Cancelled</div>)}
            {item.status === parseInt(process.env.REACT_APP_ARCHIVED_ADDRESS) && (<div className={cn("category-green")}>Archived</div>)}
            <div className={styles.text}>{item.priceAvailable}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {toLocaleStringDateFormat(item.updatedAt)}
            </div>
          </div>
          <div className={styles.col}>
            {item.status === parseInt(process.env.REACT_APP_STATUS_PENDING) ? <button
              className={cn("button-small button-red", styles.button)}
              onClick={() => setVisibleSuccessfully(true)}
            >
              Cancel
            </button>
              :
              item.status === parseInt(process.env.REACT_APP_STATUS_VERIFIED) ?
                <button
                  className={cn("button-small button-green", styles.button)}
                  onClick={() => archiveAddressHandler(item?.id)}
                >Archive</button> :
                null}

          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>




      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <CanclePopup
          item={item}
          closeModal={() => setVisibleSuccessfully(false)}
          cancelOrder={async (id) => {
            let data = {
              id: id,
              signature: localStorage.getItem('signature')

            }
            try {
              const response = await requestHandler('/cancelAddress', 'post', data, 'jwt_token');

              if (response.status === 200) {
                addNotification({
                  title: 'success', message: ' Request cancelled'
                  , type: 'success'
                })
              }
              handleAddress(response)
            } catch (error) {
              addNotification({
                title: 'error', message: error?.data?.message[0]?.msg
                , type: 'danger'
              })
            }

            setVisibleSuccessfully(false)
          }}
        />
      </Modal>
    </>
  );
};

export default Item;
