import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Download from "./Download";
import LoaderScreen from "../LoaderScreen";
import Theme from "../Theme";
import User from "./User";
import Trade from "./Trade";
import BuyCrypto from "./BuyCrypto";
import Finance from "./Finance";
import Modal from "../Modal";
import requestHandler from "../../actions/httpClient";
import Successfully from "./Successfully"

const postLoginNavigation = [
  {
    title: "Markets",
    url: "/market",
  },
  {
    title: "NFT",
    // url: "https://nft.bitdenex.com/",
  },
];

const preLoginNavigation = [
  {
    title: "Markets",
    url: "/market",
  }
];

const Header = ({ headerWide, userStatus }) => {
  const [loading, setLoading] = useState(false);
  const [visibleNav, setVisibleNav] = useState(false);
  const navigation = localStorage.getItem("signature") ? postLoginNavigation : preLoginNavigation;
  const [visibleModal, setVisibleModal] = useState(false);

  const userSwitchRegion = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        region: process.env.REACT_APP_EUROPE_MARKETS,
      };
      const userSwitchRegionPayload = await requestHandler("switchRegion", "post", data, "jwt_token");
      setLoading(false);
      if (userSwitchRegionPayload && userSwitchRegionPayload.status === 200) {
        localStorage.removeItem("signature");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("switch_exchange");
        deleteAllCookies();
        if (userSwitchRegionPayload?.data?.data[0]?.signature) {
          var signature = userSwitchRegionPayload.data.data[(userSwitchRegionPayload.data.data.length) - 1].signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = userSwitchRegionPayload.data.data[(userSwitchRegionPayload.data.data.length) - 1].url + '?sig=' + signature;
        }
        else {
          window.location.href = userSwitchRegionPayload.data.data[(userSwitchRegionPayload.data.data.length) - 1].url;
        }
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  return (
    <>
      <header className={cn(styles.header, { [styles.wide]: headerWide })}>
        <div className={cn("container", styles.container)}>
          <Link
            className={styles.logo}
            to="/"
            onClick={() => setVisibleNav(false)}
          >
            <Image
              className={styles.picDesktop}
              src="/images/logo-light.svg"
              srcdark="/images/logo-dark.svg"
              alt="Bitdenex"
            />
            <img
              className={styles.picMobile}
              src="/images/logo.svg"
              alt="Bitdenex"
            />
          </Link>
          <div className={styles.wrapper}>
            <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
              <nav className={styles.nav}>
                <BuyCrypto className={styles.dropdown} />
                <Trade className={styles.dropdown} />
                <Finance className={styles.dropdown} />
                {navigation.map((x, index) =>
                  x.dropdown ? (
                    <Dropdown
                      className={styles.dropdown}
                      key={index}
                      item={x}
                      setValue={setVisibleNav}
                    />
                  )
                    :
                    x.title.toLowerCase() === "nft" ?
                      (
                        <a
                          className={styles.item}
                          activeClassName={styles.active}
                          onClick={() => setVisibleNav(false)}
                          href={"https://nft.bitdenex.com/"}
                          key={index}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {x.title}
                        </a>
                      )
                      : (
                        <NavLink
                          className={styles.item}
                          activeClassName={styles.active}
                          onClick={() => setVisibleNav(false)}
                          to={x.url}
                          key={index}
                        >
                          {x.title}
                        </NavLink>
                      )
                )}
              </nav>
            </div>
            <div className={styles.btns}>
              {userStatus?.switchRegion === true ?
                <div className={styles.control}>
                  <button className={cn("button-stroke button-small", styles.button)}
                    onClick={() => {
                      setVisibleModal(true);
                    }}>
                    Switch account
                  </button>
                </div>
                // <Settings
                //   className={styles.settings}
                //   userSwitchRegion={userSwitchRegion}
                //   loading={loading}
                //   userStatus={userStatus}
                // />
                : ""}
              <div className={styles.control}>
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/open-orders-list"
                >
                  Orders
                </NavLink>
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/wallet-overview"
                >
                  Wallet
                </NavLink>
                <Theme className={styles.theme} icon />
                <User
                  className={styles.user}
                  userStatus={userStatus}
                />
              </div>
              <Download className={styles.download} />
            </div>
            <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            ></button>
          </div>
        </div>
      </header>
      {/* <Modal
        visible={visibleModal}
        onClose={() => { setVisibleModal(false); }}
      >
        <Successfully
          userSwitchRegion={userSwitchRegion}
          loading={loading}
          userStatus={userStatus}
          setVisibleModal={setVisibleModal}
        />
      </Modal> */}

      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Successfully
          userSwitchRegion={userSwitchRegion}
          loading={loading}
          userStatus={userStatus}
          setVisibleModal={setVisibleModal}
        />
      </Modal>
    </>
  );
};

export default Header;
