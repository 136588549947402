import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <p>
            <strong>
              Fraud prevention policy & Anti-Money Laundering and Counter-Terrorism Financing Act (Wwft)
            </strong>
          </p>
          <p>
            <strong>
              The Anti-Money Laundering Program (AML)
            </strong>
          </p>
          <h3>Article 1: General</h3>
          <p>
            <strong>1.</strong> Bitdenex follows the guidelines set for anti-money laundering (AML). <br />
            <strong>2.</strong> To trade Bitcoins, Ethereum, Litecoins and other coins, you are required to register and check all verification processes. The verification of a bank account is also included. <br />
            <strong>3.</strong> Bitdenex will report suspicious and/or fraudulent transactions according to the law Wwft. <br />
            <strong>4.</strong> We are actively monitoring transactions with fraud detection systems. <br />
            <strong>5.</strong> Criminal acts and economic offenses such as money laundering and unauthorized trading of digital coins are prohibited. <br />
            <strong>6.</strong> Any evidence of law violation is Bitdenex authorized to report to the authorities entitled thereto. <br />
            <strong>7.</strong> In case of suspicion of criminal or fraudulent acts, Bitdenex is entitled to block your Bitdenex account. This for the duration of the investigation of abuse.  <br />
            <strong>8.</strong> Bitdenex is entitled to suspend existing transactions for the duration of the investigation. If the legitimacy of the user and / or transaction (s) cannot be determined, the right to outstanding credits will expire.  <br />
            <strong>9.</strong> When Bitdenex is legally required to provide data, this will always be heard. <br />
            <strong>10.</strong> Bitdenex will not comply with any claim of user data by authorities on an illegal basis or when insufficiently substantiated and unfit for large amounts of data. <br />
            <strong>11.</strong> The judicial order of organizations entitled to it may be confiscated on your money and funds. <br />
            <strong>12.</strong> You authorize Bitdenex to do additional screening in addition to requesting the required documentation. This includes the retrieval of publicly available data as well as the consultation of non-public databases for identification and political data. <br />
          </p>

          <figure>
            <img srcSet="/images/content/aml/article.jpg" src="/images/content/aml/article.jpg" alt="Content" />
          </figure>

          <h3>Article 2: Safety procedures and measures</h3>
          <p>
            <strong>2.1: Customer Acceptance Policy:</strong><br />
            Bitdenex will at all times take care of measures and strict procedures to prevent money laundering and terrorist financing. For this reason, we have taken steps to identify you as a customer based on Article 3 of the Wwft (Politics Exposed Persons (PEP) list). Our verification processes have been adapted to these terms and conditions and all rules are respected.
          </p>
          <p>
            <strong>2.2: Transaction Monitoring:</strong><br />
            All transactions are carefully monitored and kept. The purpose is to identify and prevent suspicious activities. Bitdenex offers no place to customers with wrong intentions. We act hard to detect fraudulent transactions, which could lead to closing your Bitdenex account.
          </p>
          <p>
            <strong>2.3: Complaint handling and handling incidents:</strong><br />
            As a Bitdenex customer, you can file a complaint with Bitdenex Customer Service. If you wish, you can also file an anonymous complaint. Your information is well guaranteed. Incidents are recorded in a correct manner.
          </p>
          <p>
            <strong>2.4: Notification Unusual Transactions:</strong><br />
            Exchanges for digital coins such as Bitcoins, Ethereums, Litecoins and other cryptocurrency are covered by the Anti-Money Laundering and Terrorist Financing Act. This law enumerates which traders and exchanges do fall under it. <br />
            Bitdenex will report unusual transactions to FIU Netherlands to prevent and report fraud and fraudulent cases.
          </p>
        </div>

      </div>
    </div>
  );
};

export default Article;
