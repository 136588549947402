import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";

const Funds = ({ children, getWalletDepositListData, handleLoadMore, pageNo, loading }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <button className={styles.link}>
          <span>Deposit's Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Coin</div>
          <div className={styles.col}>Transaction Id</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {loading ? <WalletSkeleton rowCount={10} colCount={5} /> :
          getWalletDepositListData?.length > 0 ?
            <>
              {
                getWalletDepositListData?.filter((y) => {
                  if (y.symbol === process.env.REACT_APP_DEPOSIT_CRYPTO_TRX_SYMBOL) {
                    if (parseFloat(y.amount) > parseFloat(process.env.REACT_APP_DEPOSIT_CRYPTO_TRX_AMOUNT)) {
                      return y;
                    }
                  }
                  else {
                    return y;
                  }
                })
                  .map((x, index) => (
                    <Item
                      className={styles.item}
                      item={x}
                      key={index}
                      children={children}
                    />
                  ))
              }
              {getWalletDepositListData?.length === (10 * pageNo) && <div className={styles.customButton}>
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                  <span>Load more...</span>
                </button>
              </div>
              }
            </>
            :
            <div className={styles.btns}>
              <NoDataFound />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
