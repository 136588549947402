import React, { useState } from "react";
import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";
import Modal from "../../../components/Modal";
import WalletTransfer from "../../../components/WalletTransfer";

const Funds = ({ children, regionTranserList, handleLoadMore, pageno, loading }) => {
  const [visibleTransfer, setVisibleTransfer] = useState(false);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.line}>
          <button className={styles.link}>
            <span>{process.env.REACT_APP_COIN_TRANSFER_LIST_NAME} List</span>
            <Icon name="arrow-right" size="24" />
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisibleTransfer(!visibleTransfer)}
          >
            {process.env.REACT_APP_COIN_TRANSFER_LIST_NAME}
          </button>
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <WalletSkeleton rowCount={10} colCount={5} /> :
            (regionTranserList?.length > 0 ?
              <>
                {regionTranserList?.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    children={children}
                  />
                ))}
                {regionTranserList?.length === (10 * pageno) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                    <span>Load more...</span>
                  </button>
                </div>
                }
              </>
              :
              <div className={styles.btns}>
                <NoDataFound />
              </div>)
          }
        </div>
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <WalletTransfer setVisibleTransfer={setVisibleTransfer} />
      </Modal>
    </>

  );
};

export default Funds;
