import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../Icon";
const CustomDropdown = ({
  className,
  classLabel,
  value,
  setValue,
  text,
  setText,
  options,
  label,
  depositFlag,
  handleSepaClick,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  handleChange,
  walletTransferFlag,
  setCGatewayId,
  depositFiatFlag,
  depositFiatHandler,
  checkInternalAddress,
  handleInternalAddress,
  flag
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (cId, cValue, cGatewayId, name) => {
    if (depositFlag) {
      handleSepaClick(cId, cValue, cGatewayId)
    }
    if (walletTransferFlag) {
      handleChange(cId);
    }
    if (depositFiatFlag) {
      depositFiatHandler(cValue, name);
    }
    if (checkInternalAddress) {
      handleInternalAddress(cValue);
    }
    setText(cValue)
    setValue(cId);
    if (flag) {
      setCGatewayId(cGatewayId);
    }
    setVisible(false);

  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{text}</div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            <Icon name="arrow-down" size="24" />
          </div>
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          {options?.map((x, index) => (
            <div
              className={cn(classDropdownOption, styles.option, {
                [styles.selectioned]: x.cId === value,
              })}
              onClick={() => {
                handleClick(x.cId, x.cValue, x.cGatewayId, x.name);
              }}
              key={index}
            >
              {x.cValue}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
export default CustomDropdown;
