import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Item from "./Item";
import NoDataFound from "../../../components/NoDataFound";

const Funds = ({ levelData }) => {

  return (
    <div className={styles.wrap}>
      <h1 className={cn("h2", styles.title)}>
        Commission Criteria
      </h1>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Level</div>
          <div className={styles.col}>Commission Rate</div>
          <div className={styles.col}>Min Invitees</div>
          {/* <div className={styles.col}>Max Invitees</div> */}
          <div className={styles.col}>Min Volume(Fiat)</div>
          <div className={styles.col}>Assessment</div>
          {/* <div className={styles.col}>Max Volume(Fiat)</div> */}
        </div>
        {
          levelData?.length > 0 ?
            levelData?.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                key={index}
                index={index}
              />
            )) :
            <div className={styles.btns}>
              <NoDataFound className={styles.customButton} />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
