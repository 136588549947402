import styles from "./Funds.module.sass";
import cn from "classnames";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import Modal from "../../../components/Modal";
import ConfirmationModal from "./ConfirmationModal";
import StakingDetails from "./StakingDetails";

const Funds = ({
  children,
  stakingValues,
  redeemStakeHandler,
  visibleRedeemModal,
  setVisibleRedeemModal,
  setRedeemingItem,
  redeemingItem,
  setVisibleStakingDetails,
  visibleStakingDetails,
  handleTabClick,
  activeTab,
  navigation
}) => {

  return (
    <>
      <div className={cn("section-bg section-mb0", styles.releases)}>
        <div className={cn("container", styles.container)}>
          {/* <h2 className={styles.stakeTitle}>Staked amount and currency</h2> */}
          <h2 className={styles.stakeTitle}>Staking Contract</h2>
          <div className={styles.wrap}>
            <div className={styles.list}>
              <div className={styles.top}>
                <div className={styles.nav}>
                  {navigation.map((x, index) => (
                    <button
                      className={cn(`${styles.link} button-stroke`, {
                        [styles.active]: x === activeTab,
                      })}
                      onClick={() => handleTabClick(x)}
                      key={index}
                    >
                      {x}
                    </button>
                  ))}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>Coin</div>
                <div className={styles.col}>Contract Id</div>
                <div className={styles.col}>Amount</div>
                {activeTab?.toLowerCase() === "open" && <div className={styles.col}>Days Left</div>}
                <div className={styles.col}>Total earnings</div>
                {activeTab?.toLowerCase() === "open" && <div className={styles.col}>Action</div>}
              </div>
              {
                stakingValues?.length > 0 ?
                  <>
                    {
                      stakingValues?.filter(data => activeTab?.toLowerCase() === "open" ? data.status === 0 : data.status === 1)
                        ?.map((x, index) => (
                          <Item
                            className={styles.item}
                            item={x}
                            key={index}
                            children={children}
                            setVisibleRedeemModal={setVisibleRedeemModal}
                            setRedeemingItem={setRedeemingItem}
                            setVisibleStakingDetails={setVisibleStakingDetails}
                            visibleStakingDetails={visibleStakingDetails}
                            activeTab={activeTab}
                          />
                        ))
                    }
                  </>
                  :
                  <div className={styles.btns}>
                    <NoDataFound />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        onClose={() => { setVisibleRedeemModal(false) }}
        visible={visibleRedeemModal}
      >
        <ConfirmationModal
          setVisibleRedeemModal={setVisibleRedeemModal}
          redeemStakeHandler={redeemStakeHandler}
          redeemingItem={redeemingItem}
        />
      </Modal>
      <Modal
        onClose={() => { setVisibleStakingDetails(false) }}
        visible={visibleStakingDetails}
      >
        <StakingDetails
          redeemingItem={redeemingItem}
          activeTab={activeTab}
        />
      </Modal>
    </>
  );
};

export default Funds;
