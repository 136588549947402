import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = ({ closeModal }) => {
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Success!
        </div>
        <div className={styles.info}>
          Address added successfully
        </div>
        <div className={styles.info}>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={closeModal}
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
