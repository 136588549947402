import React, { useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Trade from "./Trade";

const Details = ({ allMarketsData, loading, dataMarkets, setDataMarkets }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Panel
          allMarketsData={allMarketsData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          loading={loading}
          setDataMarkets={setDataMarkets}
        />
        <Trade
          loading={loading}
          dataMarkets={dataMarkets}
          setDataMarkets={setDataMarkets}
        />
      </div>
    </div>
  );
};

export default Details;
