import React, { useState } from "react";
import cn from "classnames";
import styles from "./ConfirmOrder.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Successfully from "./Successfully";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import { useNavigate } from "react-router";
import LoaderScreen from "../../../components/LoaderScreen";

const ConfirmOrder = ({ goBack, goStart, value, price, currencyBalance }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const options = [
    {
      title: "Spend",
      content: [price],
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Method",
      content: "Instant",
      color: "#58BD7D",
      icon: "wallet",
    },

  ];

  const parameters = [
    {
      title: "You will pay",
      content: [price],
    },
  ];

  const handleBuy = async () => {
    try {

      const signature = localStorage.getItem("signature")
      setLoading(true)
      let data = {
        'marketId': value.id,
        'orderType': parseInt(1),
        'fiat_price': null,
        'fiat_spend': price,
        'crypto_spend': null,
        'signature': signature,
      }

      const payload = await requestHandler('new_order', 'post', data, 'jwt_token')
      setLoading(false)
      if (payload.status === 200) {
        addNotification({
          title: 'Success',
          message: payload.data.message[0].msg,
          type: 'success'
        });
        navigate("/open-orders-list");
        // setVisibleSuccessfully(true);
      }

    } catch (error) {
      setLoading(false)
      addNotification({
        title: 'Error',
        message: error.data.message[0].msg,
        type: 'danger'
      });
    }
  }


  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={goBack}>
            <Icon name="arrow-prev" size="14" />
            Confirm order
          </button>
          <div className={styles.money}>
            Buying {value.name}
            <img src={value.icon} alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.info}>
          You are about to buy {value.name} for {price} Eur.
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.category}>available balance {currencyBalance} Euro.</div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={goBack}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={() => handleBuy()
            }
          >
            I understand, continue
          </button>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully price={price} />
      </Modal>
      {loading && <LoaderScreen />}
    </>
  );
};

export default ConfirmOrder;
