import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { Link } from "react-router-dom";
import { twoDigitsAfterDecimal } from "../../../components/helper";

const Successfully = ({ item, closeModal, cancelOrder, cancelOrderLoading }) => {
  return (
    <>
      <div className={styles.successfully}>
        <div className={styles.info}>
          Are you sure to cancel your {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? <span style={{ color: "#58BD7D" }}>Buy</span> : <span style={{ color: "#FF6838" }}>Sell</span>} order?
        </div>
        <div className={styles.info}>
          {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
            <span>
              {item.total}
            </span>
            :
            <span style={{ color: "#FF6838" }}>
              {item.total}
            </span>
          }
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={closeModal}
            disabled={cancelOrderLoading ? true : false}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={() => cancelOrder(item?.orderId)}
          >
            {cancelOrderLoading ? "Please wait..." : 'Yes'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
