import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./DepositList.module.sass";
import Wallet from "../../components/Wallet";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const DepositList = () => {
    const [transactionListData, setTransactionListData] = useState([]);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [loading, setLoading] = useState(false);
    const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

    useEffect(() => {
        getTransactionsList();
    }, [pageRefreshFlag]);

    const getTransactionsList = async (page) => {
        setLoading(true);
        try {
            let data = {
                signature: localStorage.getItem("signature"),
            };
            const getTransactionsPayload = await requestHandler("bitdenexpay_trans_list", "post", data, "jwt_token");
            setLoading(false);
            setTransactionListData(getTransactionsPayload?.data?.data);
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "danger"
            });
        };
    };

    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                transactionListData={transactionListData}
                                loading={loading}
                                pageRefreshFlag={pageRefreshFlag}
                                setPageRefreshFlag={setPageRefreshFlag}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
        </>
    );
};






export default DepositList