import cn from "classnames";
import styles from "./StepOne.module.sass";

const StepOne = ({ goNext, userStatus }) => {
  return (
    <div>
      {(userStatus?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) || parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_CANCELLED)) && (
        <>
          {/* <div className={styles.stage}>
            Enable KYC access on your account to Enjoy  more benefits.
          </div> */}
          <div className={cn("h3", styles.title)}>
            Resident Verification  is <span>Pending</span>
          </div></>

      )}

      {userStatus?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) && (
        <>
          <div className={cn("h3", styles.title)}>
            Your Resident verification is in under Process
          </div>

        </>
      )}

      {userStatus?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) && (
        <>
          <div className={cn("h3", styles.title)}>
            Your Residence verification is  <span style={{ color: 'green' }} >completed</span>
          </div>

        </>
      )}
      {/* <div className={styles.email}>
        <Icon name="email" size="24" />
        schinner@ui8.net
      </div> */}
      {/* <div className={styles.subtitle}>Enable KYC</div> */}
      {/* <div className={styles.info}>
        To start KYC  Verification process hit the button below
      </div> */}
      {/* <div className={styles.row}>
        <TextInput
          className={styles.field}
          label="password"
          name="password"
          type="password"
          placeholder="Password"
          required
          view
        />
        <TextInput
          className={styles.field}
          label="2FA code"
          name="code"
          type="text"
          required
        />
      </div> */}

      <div className={styles.btns}>
        {(userStatus?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) || parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_CANCELLED)) && (
          <button className={cn("button", styles.button)} onClick={goNext}>
            Start Now
          </button>)}
      </div>
    </div>
  );
};

export default StepOne;
