import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import Icon from "../../../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { newOrderCreator, newOrderEmpty } from "../../../../../actions/newOrder";
import { addNotification } from "../../../../../components/Notification";
import { userStatusCreator } from "../../../../../actions/getUserStatus";

const takerFee = process.env.REACT_APP_TAKER_FEE;
const makerFee = process.env.REACT_APP_MAKER_FEE;
const spread = process.env.REACT_APP_ORDER_SPREAD;

const Action = ({
  title,
  content,
  price,
  stop,
  limit,
  classButton,
  buttonText,
  orderType,
  currentPrice,
  slug,
  marketId,
  coin,
  currency,
  buyBalance,
  sellBalance,
  orderResponse,
  currentMarketPrice
}) => {
  const dispatch = useDispatch()
  const [warning, setWarning] = useState('');
  const [sellWarning, setSellWarning] = useState('');
  const [disableButton, setDisableButon] = useState(false)
  const [sellDisableButton, setSellDisableButon] = useState(false)
  const { response, orderError, loading } = useSelector((state) => { return state.newOrder });
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  //limit price
  const [buyPriceLimit, setBuyPriceLimit] = useState('');
  const [sellPriceLimit, setSellPriceLimit] = useState('');


  //amount that user want to get
  const [amount, setAmount] = useState('');
  const [sellAmount, setSellAmount] = useState('');


  //total amount to show
  const [buyTotal, setBuyTotal] = useState('');
  const [sellTotal, setSellTotal] = useState('');
  //
  const [buyLoading, setBuyLoading] = useState(false)
  const [sellLoading, setSellLoading] = useState(false)


  //validations
  var reg = new RegExp(/^\d+(\.\d{0,8})?$/);
  useEffect(() => {
    if (amount != "" && amount > 0) {
      if (reg.test(amount) === false) {
        setWarning('Only numeric character allowed');
        setDisableButon(true)
      }
      else if (parseFloat(amount) > parseFloat(buyBalance)) {
        setWarning('Insufficiant Balance')
        setDisableButon(true)
      }
      else if (amount === 0) {
        setWarning('')
        setDisableButon(true)
      }
      else {
        setWarning('');
        setDisableButon(false)
      }
    } else {
      setWarning('');
      setDisableButon(true)
    }

  }, [amount])

  useEffect(() => {
    if (sellAmount != "" && sellAmount > 0) {
      if (reg.test(sellAmount) === false) {
        setSellWarning('Only numeric character allowed');
        setSellDisableButon(true)

      }
      else if (parseFloat(sellAmount) > parseFloat(sellBalance)) {

        setSellWarning('Insufficiant Balance')
        setSellDisableButon(true)
      }
      else if (sellAmount === 0) {
        setSellWarning('')
        setSellDisableButon(true)
      }
      else {
        setSellWarning('');
        setSellDisableButon(false)
      }
    }
    else {
      setSellWarning('');
      setSellDisableButon(true)
    }
  }, [sellAmount])

  useEffect(() => {
    if (response?.status === 200) {
      addNotification({
        title: 'Success', message: 'Order Placed '
        , type: 'success'
      })
      setSellLoading(false)
      setBuyLoading(false)
      orderResponse(response)
      dispatch(userStatusCreator())
      dispatch(newOrderEmpty())
    }
  }, [response])

  useEffect(() => {
    if (orderError?.msg) {
      addNotification({
        title: 'Error', message: orderError.msg
        , type: 'danger'
      })
      dispatch(newOrderEmpty())
    }
  }, [orderError])

  //buy calculations
  useEffect(() => {
    setBuyTotal('');
    if (limit && amount > 0) {
      // fiat calculations
      let fiat_amount = (1 - (takerFee / 100)) * parseFloat(amount);
      let fiat_spread_calculation = (1 - (spread / 100)) * parseFloat(fiat_amount);
      let fiat_spread = parseFloat(fiat_amount) - parseFloat(fiat_spread_calculation);
      // crypto calculations
      let crypto_spread = parseFloat(fiat_spread) / parseFloat(buyPriceLimit);
      let app_crypto_total = parseFloat(fiat_amount) / parseFloat(buyPriceLimit);
      let customer_crypto_total = (parseFloat(app_crypto_total) - parseFloat(crypto_spread));
      setBuyTotal(parseFloat(customer_crypto_total).toFixed(8));
    }

  }, [amount, buyPriceLimit]);

  //sell calculation

  useEffect(() => {

    setSellTotal('');
    if (limit && sellAmount > 0) {
      // fiat calculations
      let fiat_spread_calculation = (1 - (spread / 100)) * parseFloat(sellPriceLimit);
      let fiat_spread = parseFloat(sellPriceLimit) - parseFloat(fiat_spread_calculation);
      // crypto calculations
      let crypto_amount = (1 - (makerFee / 100)) * parseFloat(sellAmount);
      let app_fiat_total = parseFloat(sellPriceLimit) * parseFloat(crypto_amount);
      let customer_fiat_total = parseFloat(app_fiat_total) - parseFloat(fiat_spread);
      setSellTotal(parseFloat(customer_fiat_total).toFixed(2));
    }
  }, [sellAmount, sellPriceLimit]);


  //buy data goes here
  const buyHandler = () => {
    if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setWarning('Please verify your KYC first')
      setDisableButon(true)
    } else {
      if (limit) {
        setBuyLoading(true)
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_BUY_LIMIT_ORDER, amount, null, buyPriceLimit))
      } else {
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_BUY_MARKET_ORDER, amount))
      }
      setAmount(''); setBuyPriceLimit(currentMarketPrice);
      setBuyLoading(false);
    }

  }

  //sell data 
  const sellHandler = () => {
    if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setWarning('Please verify your KYC first')
      setDisableButon(true)
    } else {
      setSellLoading(true)
      if (limit) {
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_SELL_LIMIT_ORDER, null, sellAmount, sellPriceLimit))
      } else {
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_SELL_MARKET_ORDER, null, sellAmount))
      }
      setSellAmount(''); setSellPriceLimit(currentMarketPrice);
      setSellLoading(false);
    }
  }

  useEffect(() => {
    setBuyPriceLimit(currentMarketPrice);
    setSellPriceLimit(currentMarketPrice)
  }, [currentMarketPrice])

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter} onClick={() => { setAmount(parseFloat(content)); setSellAmount(parseFloat(content)) }}>
          <Icon name="wallet" size="16" /> {content}
        </div>
      </div>
      {price && (
        <label className={styles.field}>
          <div className={styles.label}>Price</div>
          <input className={styles.input} type="text" name="price" value="MarketPrice" required readOnly />
          <div className={styles.currency}>{currency}</div>
        </label>
      )}
      {stop && (
        <label className={styles.field}>
          <div className={styles.label}>Stop</div>
          <input className={styles.input} type="text" name="stop" required />
          <div className={styles.currency}>BTC</div>
        </label>
      )}
      {limit && (
        <label className={styles.field}>
          <div className={styles.label}>Limit Price</div>

          {orderType && orderType == "buy" && (<input className={styles.input} type="text" name="limit" value={buyPriceLimit} required onChange={(e) => setBuyPriceLimit(e.target.value)} />)}
          {orderType && orderType == "sell" && (<input className={styles.input} type="text" name="limit" value={sellPriceLimit} required onChange={(e) => setSellPriceLimit(e.target.value)} />)}
          <div className={styles.currency}>{currency}</div>
        </label>
      )}

      {orderType && orderType == "buy" && (<><label className={styles.field}>
        <div className={styles.label}>Amount</div>
        <input className={styles.input} type="text" name="amount" required value={amount} onChange={(e) => { setAmount(e.target.value) }} />
        <div className={styles.currency}>{currency}  </div>   </label><span style={{ color: 'red' }}>{warning}</span> </>)}

      {orderType && orderType == "sell" && (<><label className={styles.field}>
        <div className={styles.label}>Amount</div>
        <input className={styles.input} type="text" name="amount" required value={sellAmount} onChange={(e) => { setSellAmount(e.target.value) }} />
        <div className={styles.currency}>{coin}  </div>   </label><span style={{ color: 'red' }}>{sellWarning}</span> </>)}


      {limit && (<label className={styles.field}>
        <div className={styles.label}>Total</div>
        {orderType && orderType == "buy" && (<> <input className={styles.input} type="text" name="total" required value={buyTotal} disabled={true} readOnly />
          <div className={styles.currency}>{coin}</div></>)}


        {orderType && orderType == "sell" && (<> <input className={styles.input} type="text" name="total" required value={sellTotal} disabled={true} readOnly />
          <div className={styles.currency}>{currency}</div></>)}
      </label>)}
      {!limit && (<label className={styles.hideClass}>
        <div className={styles.label}>Total</div>
        {orderType && orderType == "buy" && (<> <input className={styles.input} type="text" name="total" required value={buyTotal} disabled={true} readOnly />
          <div className={styles.currency}>{coin}</div></>)}


        {orderType && orderType == "sell" && (<> <input className={styles.input} type="text" name="total" required value={sellTotal} disabled={true} readOnly />
          <div className={styles.currency}>{currency}</div></>)}
      </label>)}
      {orderType && orderType == "buy" && (<button className={cn(classButton, styles.button)}
        disabled={userStatus?.id === parseInt(process.env.REACT_APP_BOT_USER_ID) || loading ? true : disableButton}
        onClick={() => buyHandler()}>{buyLoading || loading ? "Please wait..." : buttonText} </button>)}
      {orderType && orderType == "sell" && (<button className={cn(classButton, styles.button)}
        disabled={userStatus?.id === parseInt(process.env.REACT_APP_BOT_USER_ID) || loading ? true : sellDisableButton}
        onClick={() => sellHandler()}>{sellLoading || loading ? "Please wait..." : buttonText} </button>)}
    </>
  );
};

export default Action;
