import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";

const Page = ({ headerHide, children, footerHide, headerWide }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  useEffect(() => {
    if (localStorage.getItem('signature') !== null) {
      if (userStatus?.length === 0) {
        dispatch(userStatusCreator());
      };
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <>
      <div className={styles.page}>
        {!headerHide && <Header
          headerWide={headerWide}
          userStatus={userStatus}
        />}
        <div className={styles.inner}>{children}</div>
        {!footerHide && <Footer />}
      </div>
    </>
  );
};

export default Page;
