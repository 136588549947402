import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./WhitelistAddress.module.sass";
import Wallet from "../../components/Wallet";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";

const WhitelistAddresses = () => {
    const [visibleWithdraw, setVisibleWithdraw] = useState(false);
    const [coinsData, setCoinsData] = useState([])
    const [allAddress, setAllAddress] = useState([])
    const [updateAddress, setUpdateAddress] = useState()
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(true);
    // const { coinsData } = useSelector((state) => { return state.coins }
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [refreshFlag, setRefreshFlag] = useState(false);

    const handleLoadMore = () => {
        setPageNo(pageNo + 1)
    };

    const handleAddress = (value) => {
        setUpdateAddress(value)
    }

    useEffect(() => {
        getCoinData()
    }, [])

    useEffect(() => {
        getAddressData(pageNo);
    }, [updateAddress, pageNo, refreshFlag])

    var data = {
        signature: localStorage.getItem("signature"),
    };

    const getCoinData = async () => {
        try {

            const payload = await requestHandler("getCoins", "post", data, "jwt_token");

            setCoinsData(payload)
        }
        catch (e) {
        };
    };

    const getAddressData = async (page) => {
        setLoading(true);
        try {
            data["page"] = page
            const payload = await requestHandler('getAllAddress', 'post', data, 'jwt_token');
            setLoading(false);
            setAllAddress(payload.data)

        } catch (error) {
            setLoading(false);
        }

    };

    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                coinsData={coinsData}
                                allAddress={allAddress}
                                handleAddress={handleAddress}
                                handleLoadMore={handleLoadMore}
                                pageNo={pageNo}
                                loading={loading}
                                setLoading={setLoading}
                                setRefreshFlag={setRefreshFlag}
                                refreshFlag={refreshFlag}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
            <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw />
            </Modal>
        </>
    );
};






export default WhitelistAddresses