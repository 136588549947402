import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "General",
    items: [
      {
        title: "What is Bitdenex? ",
        content:
          'Bitdenex is a European Cryptocurrency Exchange, also known as a trading platform, based in the Netherlands. At Bitdenex you can buy and sell coins such as Bitcoin, Ethereum, Litecoin and other coins quickly, safely and easily. In the exchange you can easily exchange coins with other available coins. ',
      },
      {
        title: "Why Bitdenex? ",
        content:
          'Buy and sell cryptocurrency or digital coins can be difficult, especially if you have not previously bought a coin. It can sometimes take a long time to investigate how you can actually buy a coin. Unfortunately, not all exchanges offer all coins. You can view per cryptocurrency you want to buy at which exchange that cryptocurrency is available. With Bitdenex Exchange you can buy and sell coins easily and quickly. You can choose from the payment methods such as (very popular) iDeal. Once the payment is successful, you will immediately receive the purchased coins in your Bitdenex account. ',
      },
      {
        title: " What do you offer? ",
        content:
          'Bitdenex provide opportunity to interested people to find interesting projects in cryptocurrency. On the other hand, individuals may find the right trading platform to fulfill their investment dreams. Bitdenex don’t give advice to anyone. We only create the opportunity for the people to buy and sell cryptocurrency easily. ',
      },
      {
        title: "Does Bitdenex require a licence from the authorities ? ",
        content:
          'NO. Bitdenex is a trading platform for cryptocurrencies. In this capacity Bitdenex does not require a license from both Dutch Central Bank (DNB) and Authorities Financiele Markets (AFM). Bitdenex don’t offer financial products to our customers where we need financial data from individual customers. Bitdenex only offers the possibility to buy and sell a cryptocurrency at your own risk. When a license is required, Bitdenex will apply to the authorities to get a license. ',
      },
      {
        title: "Does it matter where I am from? ",
        content:
          'Yes. Bitdenex offer our service in Europe. As a non-European citizen, you can’t get access to the trading platform. ',
      },

      {
        title: "Do I need skills to use the service? ",
        content:
          'No. Bitdenex trading platform is made user-friendly so it is simple and easy to trade. Moreover, Bitdenex team will provide you any support you need! ',
      },

      {
        title: "Why should I join Bitdenex? ",
        content:
          'By joining Bitdenex you will have a chance to contribute to the fair market and decentralized eco-system.',
      },

      {
        title: " Why do I have to create an Bitdenex account? ",
        content:
          'To prevent fraud and scam, it is necessary to create an account. Your account must be verified so that we can be sure that you are the right person. Through these identification and verification obligations we try to prevent fraud and scam as much as possible. You also need to validate a bank account once in your Bitdenex account. After all identification and verification obligations you can start trading digital coins. ',
      },

      {
        title: " Can I withdraw my Give-aways or Bonuses received by Bitdenex? ",
        content:
          'No, Bonuses and Give-aways given by Bitdenex cannot be withdrawn, you are not allowed to withdraw your received “Give-aways” or “Bonuses”, unless you make a first deposit and make a trade. Bonuses and Give-aways are forfeited upon any asset withdrawal. ',
      },
    ],
  },
  {
    title: "Account Verification",
    items: [
      {
        title: "Why do I need to verify my account? ",
        content:
          'The verification process is related to the obligations imposed by Dutch legislation and regulations to prevent fraud and scam. Based on the laws and regulations, Bitdenex are required to verify a number of details of our customers. ',
      },
      {
        title: "How can I verify my Bitdenex account? ",
        content:
          'You must complete the verification process when you sign up. During the process, you must provide documentation so that we can verify and validate your account. If you are not sure whether Bitdenex is the trading platform where you want to trade, create an account for free and take a look at the trading platform. ',
      },
      {
        title: "How long does the verification takes? ",
        content:
          'We aim to process your request within one working day after you have uploaded all the necessary documentation and completed the verification process. You will receive a confirmation immediately by e-mail after your account has been approved. If we require additional or replacement documentation, we will notify you by e-mail. You will have enough time and space to restore your account and provide the correct documentation. ',
      },
      {
        title: "Which documents are  required ? ",
        content: 'Documents required for verification are Copy of identity card, Proof of location / address / residence and Bank statement, photo of your bank card or screenshot of your online banking (payments may be hidden). Bitdenex reserves the right to request additional documentation at any time that the Dutch laws and regulations prescribe.'



      },
    ],
  },
  {
    title: " Security",
    items: [
      {
        title: " Where can I change my data? ",
        content:
          'You can change your data under the “My Account” button. Here you can change your account details, adjust settings for notifications and make changes to the security of your account. ',
      },
      {
        title: "What to do when “password forgotten” or “create new password”? ",
        content:
          'If you have forgotten your password, you must go through the process of “forgotten password” to create a new password. To create a new password you must first login and under the button “My Account” you can create a new password under “Security”. ',
      },
      {
        title: " Issue with Google Authenticator? ",
        content:
          'You can restore your Two Factor Authentication (Google Authenticator) using your 16-Digit key. You should keep your 16-Digit key properly when using a 2FA. For other questions you can always email us. ',
      },
    ],
  },
  {
    title: "Trading",
    items: [
      {
        title: " How long does a order take? ",
        content:
          ' All transactions are processed instantaneously. That means that your buy and sell order will be visible in your account within seconds. If you want to transfer coins to another Wallet (digital wallet), we depend on the Blockchain system and speed, which can cause some delays.',
      },
      {
        title: "How do I trade digital coins? ",
        content:
          'On the trading platform you can trade all available coins using a market or limit order. If you opt for a market order, we will fulfill your order directly based on the bid or late order of that moment. If you opt for a limit order, we will fulfill your order once the selected currency has reached your indicated purchase or sale price. When exchanging digital coins, the coins will immediately be exchanged on the basis of market order. Unfortunately, you can not indicate at what price you want to exchange certain coins. ',
      },
      {
        title: "Cancellation of order? ",
        content:
          'You can cancel your order in the trading platform until the moment of fulfillment. Market orders are instantaneously fulfilled, that means, your order is immediately fulfilled. If possible, you can change your limit order and cancel it until it is fulfilled. A completed transaction can no longer be canceled. ',
      },

    ],
  },

  {
    title: "Deposit and withdraw",
    items: [
      {
        title: " How long does it take before deposits are visible? ",
        content:
          'The speed of your deposit depends on your payment method. Bitcoin: With Bitcoins deposits we are dependent on the Blockchain network. Usually within 30-60 minutes, after 3 network confirmations your Bitcoins will be credited or debited from your account. Sometimes it can take longer because the network is too busy. ',
      },
      {
        title: " Wallets changes, is that possible? ",
        content:
          'NO. Unfortunately, you can not change your Bitcoin address, Ethereum address or other addresses. You can, however, create new pouring addresses. In addition, you can continue to use your old addresses. You may create as many addresses as you think you need. ',
      },

    ],
  },



];

const Faq = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>Frequently asked questions</h2>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
