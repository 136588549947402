import React from "react";
import cn from "classnames";
import styles from "./ImportantNotes.module.sass";

const ImportantNotes = ({ formData, goNext, submitHandler, getUnconfirmedTransactionHandler }) => {
  return (
    <div className={styles.item}>
      <div className={styles.title}>Important notes</div>
      <div className={styles.text}>
        We ONLY accept fundings from a bank account under your own name.
      </div>
      {formData.bankText && formData.bankText.toLowerCase().includes("sepa") && (
        <>
          <div className={styles.text}>
            Multiple deposits of the same amounts within a few days of each other may be delayed. If you must make multiple deposits, consider sending different amounts.
          </div>
          <div className={styles.text}>
            You MUST include the Reference Code in your deposit in order to credit
            your account! <br></br>Reference Code:
          </div>
          <div className={styles.code}>{formData.reference_code}</div>
          <div className={styles.text}>
            This code identifies you deposit with your account. Include this code when submitting the wire transfer.
          </div></>
      )}
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            if (formData.bankText.toLowerCase().includes("sepa")) {
              submitHandler();
              goNext();
            }
            else {
              goNext();
            }
          }}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default ImportantNotes;