import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import requestHandler from "../../actions/httpClient";
import { socket } from "../../socket";

const Market = () => {

  const [allMarketsData, setAllMarketsData] = useState([]);
  const [dataMarkets, setDataMarkets] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // getRegion();

    socket.connect();

    socket.on("europe_markets_web", (data) => {
      setLoading(false);
      setAllMarketsData(data);
      setDataMarkets(data[0]?.markets);
      setLoading(false);
    });

    return (() => {
      socket.off("europe_markets_web");
      socket.disconnect();
    });

  }, []);

  const getRegion = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_EUROPE_MARKETS) {
          getAllMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
    };
  };

  const getAllMarkets = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "allMarkets",
        region: id,
        signature: localStorage.getItem('signature')
      };
      const marketsPayload = await requestHandler("get_markets", "post", data, 'jwt_token');
      setAllMarketsData(marketsPayload?.data?.data);
      setDataMarkets(marketsPayload?.data?.data[0]?.markets);
      setLoading(false)
    } catch (e) {
      setLoading(false)
    };
  };

  return (
    <>
      <Main />
      <Details
        allMarketsData={allMarketsData}
        loading={loading}
        dataMarkets={dataMarkets}
        setDataMarkets={setDataMarkets}
      />
    </>
  );
};

export default Market;
