import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Withdraw.module.sass";
import TextInput from "../TextInput";
import Successfully from "./Successfully";
import Code from "../../screens/WalletOverview/Code";
import CustomDropdown from "../CustomDropdown";
import SimpleReactValidator from "simple-react-validator";
import Dropdown from "../Dropdown";
import { addNotification } from "../Notification";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../LoaderScreen";
import { getDigitsAfterDecimal } from "../helper";

let addressOptions = [];
const Withdraw = ({
  state,
  setVisibleSuccessfully,
  visibleSuccessfully,
  withdrawNewAddressHandler,
  coinsData,
  withdrawAddressHandler,
  walletAddressData,
  codeScreenHandler,
  setActiveIndex,
  activeIndex,
  userStatus,
  loader,
  addressLoading
}) => {
  const [coinText, setCoinText] = useState();
  const [text, setText] = useState("Select address");
  const [coin, setCoin] = useState();
  const [visibleWithdraw, setVisibleWithdraw] = useState(true);
  const [address, setAddress] = useState();
  const [amount, setAmount] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();
  const [newAddress, setNewAddress] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [ownership, setOwnership] = useState("");
  const [addressProof, setAddressProof] = useState("");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [selectedCoin, setSelectedCoin] = useState([]);
  const [tokenOptions, setTokenOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [token, setToken] = useState("Select network");
  const [currentSelected, setCurrentSelected] = useState([]);
  const [visibleProperty, setVisibleProperty] = useState(true);
  const [tokenMaxFee, setTokenMaxFee] = useState([]);
  const [tokenIndex, setTokenIndex] = useState();
  const [loading, setLoading] = useState();
  const [btnProperty, setBtnProperty] = useState(true);
  const [hideWithdrawButton, setHideWithdrawButton] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [tag, setTag] = useState("");
  const [showTagField, setShowTagField] = useState();

  useEffect(() => {
    if (address) {
      walletAddressData
        ?.filter((x) => x.id === address)
        .map((x) => {
          setSelectedAddress(x.maxFee);
        });
    } else {
      setSelectedAddress("0.0");
      addressOptions = [];
      walletAddressData?.map((x) =>
        addressOptions.push({
          cId: x.id,
          cValue: x.ownership + " (" + x.address + ")",
        })
      );
    }
  }, [address]);

  useEffect(() => {
    if (coinsData) {
      coinsData?.data?.data
        ?.filter((x) => x.symbol === state.symbol)
        .map((x) => {
          setTokenMaxFee(x?.network_max_fee);
          setSelectedCoin(x.maxFee);
          setCurrentSelected(x);
          setTokenOptions(x.network_slug);
          setCoin(x.id);
          x?.is_tag_enable === 1 ? setShowTagField(true) : setShowTagField(false);
          setCoinText(
            x.symbol.toUpperCase() + "(" + x.name?.toUpperCase() + ")"
          );
        });
    }
  }, []);

  const handleClick = () => {
    setVisibleWithdraw(false);
  };

  const handleCode = () => {
    setVisibleSuccessfully(true);
  };

  const addressVerify = async () => {
    setLoading(true);
    if (withdrawAddress != '') {
      const data = {
        signature: localStorage.getItem("signature"),
        address: withdrawAddress,
        coinId: coin,
      };
      // console.log("data", data);
      try {
        const adressPayload = await requestHandler("checkInternalAddress", "post", data, "jwt_token");
        // console.log("adressPayload", adressPayload);
        setLoading(false);
        if (adressPayload.data.message[0].status === false) {
          // setBtnProperty(false);
          setVisibleProperty(true);
        } else {
          // setBtnProperty(true);
          setVisibleProperty(false);
          addNotification({
            title: "Alert",
            message: "Note: This address belongs to Bitdenex, you can continue without whitelisting this address.",
            type: "danger"
          })
        }
      } catch (error) {
        setLoading(false);
      }
    }
    else {
      // setBtnProperty(true);
      setLoading(false);
    }
  };

  const checkInternalAddressHandler = async (address) => {
    setLoading(true);
    if (address) {
      let newAddress = address?.split("(")[1]?.split(")")[0];
      setLoading(true);
      let data = {
        signature: localStorage.getItem("signature"),
        address: newAddress,
        coinId: coin,
      };
      try {
        const addressPayload = await requestHandler("checkInternalAddress", "post", data, "jwt_token");
        setLoading(false);
        if (addressPayload.data.message[0].status === false) {
          setBtnProperty(false);
          setVisibleProperty(true);
        }
        else {
          setBtnProperty(true);
          setVisibleProperty(false);
          addNotification({
            title: "Alert",
            message: "Note: This address belongs to Bitdenex. No transaction fee for this withdraw.",
            type: "danger"
          })
        }
      }
      catch (error) {
        setLoading(false);
      }
    }
    else {
      setBtnProperty(true);
      setLoading(false);
    }
  };

  const cryptoAddressHandler = (coin, token, address) => {
    if (coin && token != "Select network") {
      if (token.includes("ERC")) {
        const ercRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ercRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setHideWithdrawButton(true);
          setIsValid(false);
        }
      }
      else if (token.includes("TRC")) {
        const trxRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;
        if (trxRegex.test(address)) {
          setHideWithdrawButton(false);
          setIsValid(true);
        }
        else {
          setHideWithdrawButton(true);
          setIsValid(false);
        }
      }
      else if (coin.includes("BNB")) {
        const bnbRegex = /^(0x)[0-9A-Fa-f]{40}$/
        if (bnbRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setHideWithdrawButton(true);
          setIsValid(false);
        }
      }
    }
    else if (coin && token === "Select network") {
      if (coin.includes("BTC")) {
        const bitcoinRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
        if (bitcoinRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("ETH")) {
        const ethereumRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ethereumRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("XRP")) {
        const rippleRegex = /^([r])([1-9A-HJ-NP-Za-km-z]{24,34})$/gm;
        if (rippleRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("LTC")) {
        const ltcRegex = /^(L|M|3|ltc1)[a-km-zA-HJ-NP-Z1-9]{26,}$|^(ltc1)[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{8,}$/;
        if (ltcRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("TRX")) {
        const trxRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;
        if (trxRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("QTUM")) {
        const qtumRegex = /^(Q|q)[0-9a-zA-Z]{26,35}$/;
        if (qtumRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("XLM")) {
        const stellarRegex = /^G[A-Z2-7]{55}$/;
        if (stellarRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("SOL")) {
        const solanaRegex = /^(?!.*[IOio])[1-9A-HJ-NP-Za-km-z]{43}$/;
        if (solanaRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("DOGE")) {
        const dogecoinRegex = /^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{26,35}$|^(d|a|9)[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{33,}$|^(D|A|9)[2-9A-HJ-NP-Za-km-z]{52,53}$/;
        if (dogecoinRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if (coin.includes("ADA")) {
        const cardanoRegex = /^(addr1|addr2)[a-km-zA-HJ-NP-Z1-9]{58}$/
        if (cardanoRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
      else if ((coin === "Please select coin" && token === "Please select network token")) {
        setIsValid(true);
        setHideWithdrawButton(false);
      }
      else {
        const ethereumRegex = /^0x[a-fA-F0-9]{40}$/;
        if (ethereumRegex.test(address)) {
          setIsValid(true);
          setHideWithdrawButton(false);
        }
        else {
          setIsValid(false);
          setHideWithdrawButton(true);
        }
      }
    }
  };

  // const isValidCryptoAddressHandler = (coin, token, address) => {
  //   if (coin && token === "Select network" && address != "") {
  //     cryptoAddressHandler(coin, token, address);
  //   }
  //   else if (coin !== "Please select coin" && token != "Please select network token" && address != "") {
  //     cryptoAddressHandler(coin, token, address);
  //   }
  // };

  // useEffect(() => {
  //   if (isValid && withdrawAddress !== "") {
  //     console.log("hii", isValid, withdrawAddress);
  //     addressVerify();
  //   }
  // }, [isValid]);

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          {loading && <LoaderScreen />}
          {!newAddress && (
            <>
              <div className={cn("h4", styles.title)}>
                Withdraw {state.symbol.toUpperCase()}
              </div>
              <button className={cn("button-small", styles.button)} onClick={() => { setNewAddress(!newAddress); setVisibleProperty(true); }} >
                Add New Address
              </button>

              <div className={styles.field}>
                <CustomDropdown
                  className={styles.dropdown}
                  label="Address"
                  value={address?.name}
                  setValue={setAddress}
                  text={text}
                  setText={setText}
                  options={addressOptions}
                  checkInternalAddress={true}
                  handleInternalAddress={(x) => checkInternalAddressHandler(x)}
                />
              </div>

              <span className={styles.danger}>
                {simpleValidator.current.message(
                  "address",
                  address,
                  "required"
                )}
              </span>
              <div className={styles.wrap}>
                <div className={styles.category}>
                  Available<br></br>balance
                </div>
                <div className={styles.details}>
                  <div className={styles.currency}>{`${getDigitsAfterDecimal(state?.balance, state?.decimalPrecision)
                    } ${state.symbol.toUpperCase()}`}</div>
                </div>
              </div>
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Amount to withdraw"
                  name="amount"
                  type="text"
                  placeholder="Please enter amount"
                  required
                  value={amount}
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setAmount(e.target.value)
                    }
                  }}
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("amount");
                  }}
                />
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => setAmount(getDigitsAfterDecimal(state.balance, state?.decimalPrecision))}
                >
                  Max amount
                </button>
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message("amount", amount, "required")}
              </span>
              {!visibleProperty &&
                <div className={styles.customWrap}>
                  <div className={styles.info}>
                    Note: This address belongs to Bitdenex. No transaction fee for this withdraw.
                  </div>
                </div>
              }
              {visibleProperty &&
                <>
                  <TextInput
                    className={styles.field}
                    label="transaction fee"
                    name="transaction"
                    type="text"
                    placeholder="0.0"
                    disabled={true}
                    required
                    value={parseFloat(selectedAddress)}
                  />
                  <TextInput
                    className={styles.field}
                    label="amount to receive"
                    name="total"
                    type="text"
                    required
                    disabled={true}
                    value={
                      (parseFloat(amount) - parseFloat(selectedAddress) > 0) ? getDigitsAfterDecimal((!amount ? "0.0" : parseFloat(amount) - parseFloat(selectedAddress)).toFixed(state?.decimalPrecision), state?.decimalPrecision) : "0.00"
                    }
                  />
                </>
              }
              <button
                // disabled={btnProperty}
                className={cn("button", styles.button)}
                onClick={(e) => {
                  e.preventDefault();
                  if (simpleValidator.current.allValid()) {
                    if (!visibleProperty) {
                      if (parseFloat(amount) <= 0) {
                        addNotification({
                          title: "Alert",
                          message:
                            "You can't withdraw 0 amount.",
                          type: "danger",
                        });
                      }
                      else if (parseFloat(amount) > parseFloat(state.balance)) {
                        addNotification({
                          title: "Alert",
                          message:
                            "You don't have that much of amount available in your wallet",
                          type: "danger",
                        });
                      }
                      else {
                        withdrawAddressHandler(state.coinId, amount, address);
                        handleClick();
                      }
                    }
                    else {
                      let finalAmount = 0;
                      if (state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN)) {
                        finalAmount = tokenMaxFee[tokenIndex];
                      }
                      else {
                        finalAmount = selectedAddress
                      }
                      if (parseFloat(amount) > parseFloat(state.balance) || parseFloat(amount) <= 0 ||
                        (parseFloat(amount) - parseFloat(selectedAddress) <= 0)
                      ) {
                        addNotification({
                          title: "Alert",
                          message:
                            "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                          type: "danger",
                        });
                      } else if (parseFloat(finalAmount) <= 0) {
                        addNotification({
                          title: "Alert",
                          message: "Entered amount is too low to withdraw",
                          type: "danger",
                        });
                      } else {
                        withdrawAddressHandler(state.coinId, amount, address);
                        handleClick();
                      }
                    }
                  } else {
                    simpleValidator.current.showMessages();
                    setForceUpdate(forceUpdate + 1);
                  }
                }}
              >
                Withdraw
              </button>
            </>
          )}

          {newAddress && (
            <div className={styles.transfer}>
              <div className={cn("h4", styles.title)}>
                Withdraw {state.symbol.toUpperCase()}
              </div>
              <button
                className={styles.category}
                onClick={() => setNewAddress(!newAddress)}
              >
                Address
              </button>
              <div className={styles.field}>
                <TextInput
                  className={styles.dropdown}
                  name="coin"
                  label="Select Coin"
                  value={coinText}
                  disabled={true}
                />
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message("coin", coin, "required")}
              </span>
              {currentSelected?.stableCoin ===
                parseInt(process.env.REACT_APP_UNSTABLE_COIN) && coin ? (
                <>
                  <div className={styles.field}>
                    <Dropdown
                      className={styles.dropdown}
                      label="Select token network"
                      value={token}
                      setValue={setToken}
                      options={tokenOptions}
                      setIndex={setTokenIndex}
                    // addressValidatorFlag={true}
                    // addressValidator={(value) => { isValidCryptoAddressHandler(coinText, value, withdrawAddress) }}
                    />
                  </div>
                </>
              ) : null}

              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Enter Address"
                  name="address"
                  type="text"
                  placeholder="Please enter a address"
                  note="Please be sure that the address is related to the coin/token that you are withdrawing."
                  required
                  value={withdrawAddress}
                  onChange={(e) => setWithdrawAddress(e.target.value)}
                  onBlur={addressVerify}
                // onBlur={() => { isValidCryptoAddressHandler(coinText, token, withdrawAddress) }}
                />
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message(
                  "address",
                  withdrawAddress,
                  "required"
                )}
              </span>
              {(showTagField) &&
                <>
                  <div className={styles.box}>
                    <TextInput
                      className={styles.field}
                      label="Enter tag"
                      name="tag"
                      type="text"
                      placeholder="Please enter a tag"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                    />
                  </div>
                  <span className={styles.danger}>
                    {simpleValidator.current.message("tag", tag, "required")}
                  </span>
                </>
              }

              {!isValid &&
                <div className={styles.customWrapClass}>
                  <div className={styles.addressInfo}>
                    Note: The address is invalid!
                  </div>
                </div>
                // :() => { addressVerify()
              }

              {!visibleProperty &&
                <div className={styles.customWrap}>
                  <div className={styles.info}>
                    Note: This address belongs to Bitdenex, you can continue without whitelisting this address.
                  </div>
                </div>
              }
              {visibleProperty && (
                <>
                  <div className={styles.box}>
                    <TextInput
                      className={styles.field}
                      label="Name Of Exchange Or Ledger"
                      name="exchangeName"
                      type="text"
                      placeholder="Please enter name of exchange"
                      value={ownership}
                      required
                      onChange={(e) => setOwnership(e.target.value)}
                    />
                  </div>
                  <span className={styles.danger}>
                    {simpleValidator.current.message(
                      "exchangeName",
                      ownership,
                      "required"
                    )}
                  </span>
                  <div className={styles.box}>
                    <TextInput
                      className={styles.field}
                      label="Scan With Proof Of Ownership"
                      name="addressProof"
                      type="file"
                      note="Use Screenshot of your address where it exists."
                      required
                      onChange={(e) => setAddressProof(e.target.files[0])}
                    />
                  </div>
                  <span className={styles.danger}>
                    {simpleValidator.current.message(
                      "addressProof",
                      addressProof,
                      "required"
                    )}
                  </span>
                  <div className={styles.wrap}>
                    <div className={styles.info}>
                      Provide a photo that shows your ID and your wallet or
                      exchange account with the cryptocurrency address clearly
                      visible. Both your Id and address need to be visible in
                      the same photo.
                    </div>
                  </div>{" "}
                </>
              )}
              <div className={styles.wrap}>
                <div className={styles.category}>
                  Available<br></br>balance
                </div>
                <div className={styles.details}>
                  <div className={styles.currency}>{`${getDigitsAfterDecimal(state?.balance, state?.decimalPrecision)} ${state.symbol.toUpperCase()}`}</div>
                </div>
              </div>
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Amount to withdraw"
                  name="amount"
                  type="text"
                  placeholder="Please enter amount"
                  required
                  value={enteredAmount}
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setEnteredAmount(e.target.value)
                    }
                  }}
                />
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={(e) => {
                    setEnteredAmount(getDigitsAfterDecimal(state.balance, state.decimalPrecision));
                  }}
                >
                  Max amount
                </button>
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message(
                  "amount",
                  enteredAmount,
                  "required"
                )}
              </span>
              {visibleProperty &&
                <>
                  {state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) ?
                    <TextInput
                      className={styles.field}
                      label="transaction fee"
                      name="transaction"
                      type="text"
                      placeholder="0.0"
                      disabled={true}
                      required
                      value={tokenMaxFee[tokenIndex]
                        ? parseFloat(tokenMaxFee[tokenIndex])
                        : "0.0"}
                    /> :
                    <TextInput
                      className={styles.field}
                      label="transaction fee"
                      name="transaction"
                      type="text"
                      placeholder="0.0"
                      disabled={true}
                      required
                      value={parseFloat(selectedCoin) ? parseFloat(selectedCoin) : "0.00"}
                    />}
                </>
              }
              {visibleProperty &&
                <>
                  {state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) ?
                    <TextInput
                      className={styles.field}
                      label="amount to receive"
                      name="total"
                      type="text"
                      placeholder="0.0"
                      required
                      disabled={true}
                      value={(parseFloat(enteredAmount) - parseFloat(tokenMaxFee[tokenIndex]) > 0) ? getDigitsAfterDecimal((!enteredAmount
                        ? "0.0"
                        : parseFloat(enteredAmount) - parseFloat(tokenMaxFee[tokenIndex]).toFixed(state?.decimalPrecision)), state?.decimalPrecision) : "0.00"}
                    />
                    :
                    <TextInput
                      className={styles.field}
                      label="amount to receive"
                      name="total"
                      type="text"
                      placeholder="0.0"
                      required
                      disabled={true}
                      value={
                        (parseFloat(enteredAmount) - parseFloat(selectedCoin) > 0) ? getDigitsAfterDecimal((!enteredAmount
                          ? "0.0"
                          : parseFloat(parseFloat(enteredAmount) - parseFloat(selectedCoin)).toFixed(state?.decimalPrecision)), state?.decimalPrecision) : "0.00"
                      }
                    />
                    // Added one extra parsfloat and tofixed function
                  }
                </>
              }
              <button
                className={cn("button", styles.button)}
                // disabled={hideWithdrawButton}
                onClick={(e) => {
                  e.preventDefault();
                  if (simpleValidator.current.allValid()) {
                    if (parseFloat(enteredAmount) > parseFloat(state.balance) || parseFloat(enteredAmount) === 0) {
                      addNotification({
                        title: "Alert",
                        message:
                          "You don't have that much of amount available in your wallet.",
                        type: "danger",
                      });
                    }
                    else if (
                      state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) &&
                      parseFloat(enteredAmount) - parseFloat(tokenMaxFee[tokenIndex]) <= 0) {
                      addNotification({
                        title: "Alert",
                        message: "Entered amount is too low to withdraw",
                        type: "danger",
                      });
                    }
                    else if (state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && token === "Select network") {
                      addNotification({
                        title: "Alert",
                        message: "Please select coin network",
                        type: "danger",
                      });
                    }
                    else if (state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) &&
                      parseFloat(enteredAmount) - parseFloat(selectedCoin) <= 0) {
                      addNotification({
                        title: "Alert",
                        message: "Entered amount is too low to withdraw",
                        type: "danger",
                      });
                    }
                    else if (state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) &&
                      visibleProperty === false && coin != "" && enteredAmount != "" && withdrawAddress != "") {
                      if (state?.balance < enteredAmount) {
                        addNotification({
                          title: "Alert",
                          message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                          type: "danger",
                        });
                      }
                      else {
                        withdrawNewAddressHandler(
                          coin,
                          withdrawAddress,
                          ownership,
                          addressProof,
                          enteredAmount,
                          token
                        );
                        handleClick();
                      }
                    }
                    else {
                      if (showTagField) {
                        if (tag !== "") {
                          withdrawNewAddressHandler(
                            coin,
                            withdrawAddress,
                            ownership,
                            addressProof,
                            enteredAmount,
                            token,
                            tag
                          );
                          handleClick();
                        }
                        else {
                          addNotification({
                            title: "Alert",
                            message: "Please filled all the fields.",
                            type: "danger",
                          });
                        }
                      }
                      else {
                        withdrawNewAddressHandler(
                          coin,
                          withdrawAddress,
                          ownership,
                          addressProof,
                          enteredAmount,
                          token,
                          tag
                        );
                        handleClick();
                      }
                    }
                  }
                  else {
                    if (showTagField) {
                      if (tag !== "") {
                        if (visibleProperty === false && coin != "" && enteredAmount != "" && withdrawAddress != "") {
                          if (parseFloat(state?.balance) < parseFloat(enteredAmount) || parseFloat(enteredAmount) <= 0) {
                            addNotification({
                              title: "Alert",
                              message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                              type: "danger",
                            });
                          }
                          else {
                            withdrawNewAddressHandler(
                              coin,
                              withdrawAddress,
                              ownership,
                              addressProof,
                              enteredAmount,
                              token,
                              tag
                            );
                            handleClick();
                          }
                        } else {
                          simpleValidator.current.showMessages();
                          setForceUpdate(forceUpdate + 1);
                        }
                      }
                      else {
                        addNotification({
                          title: "Alert",
                          message: "Please fill all the fields.",
                          type: "danger",
                        });
                      }
                    }
                    else {
                      if (visibleProperty === false && coin != "" && enteredAmount != "" && withdrawAddress != "") {
                        if (parseFloat(state?.balance) < parseFloat(enteredAmount) || parseFloat(enteredAmount) <= 0) {
                          addNotification({
                            title: "Alert",
                            message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                            type: "danger",
                          });
                        }
                        else {
                          withdrawNewAddressHandler(
                            coin,
                            withdrawAddress,
                            ownership,
                            addressProof,
                            enteredAmount,
                            token
                          );
                          handleClick();
                        }
                      } else {
                        simpleValidator.current.showMessages();
                        setForceUpdate(forceUpdate + 1);
                      }
                    }

                    simpleValidator.current.showMessages();
                    setForceUpdate(forceUpdate + 1);
                  }
                }}
              >
                Add New Address & Withdraw
              </button>
            </div>
          )}
        </div>
      )}
      {loader && <LoaderScreen />}
      {activeIndex === 1 && (
        <Code
          state={state}
          handleCode={handleCode}
          codeScreenHandler={codeScreenHandler}
          userStatus={userStatus}
        />
      )}
      {activeIndex === 2 && <Successfully />}
    </>
  );
};

export default Withdraw;
