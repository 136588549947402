import styles from "./SelectQuestions.module.sass";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import { useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";
import LoaderScreen from "../../../components/LoaderScreen";

const transferFund = ["Yes", "No"];

const SelectQuestions = ({ loading, handleSubmit, getBankVerificationQuestionsData, coinsData, questionsData }) => {
    const [fiatOptions, setFiatOptions] = useState([]);
    const [accountOptions, setAccountOptions] = useState([]);
    const [coinsOptions, setCoinsOptions] = useState([]);
    const [annualIncomeOptions, setAnualIncomeOptions] = useState([]);
    const [grossAnnualIncomeOptions, setGrossAnualIncomeOptions] = useState([]);
    const [fiat, setFiat] = useState("Select a value");
    const [account, setAccount] = useState("Select a value");
    const [coinText, setCoinText] = useState("Select a value");
    const [volume, setVolume] = useState("Select a value");
    const [annualIncome, setAnualIncome] = useState("Select a value");
    const [fund, setFund] = useState(transferFund[0]);
    const [profession, setProfession] = useState("");

    useEffect(() => {
        if (coinsData?.data?.data?.length > 0 && Object.keys(getBankVerificationQuestionsData).length > 0) {
            setCoinsOptions(coinsData?.data?.data?.filter(x => x.symbol !== "eur").map(x => (x.name.toUpperCase())))
            setFiatOptions(getBankVerificationQuestionsData?.fiat_from);
            setAccountOptions(getBankVerificationQuestionsData?.why_open_account);
            setAnualIncomeOptions(getBankVerificationQuestionsData?.average_trading_annual_volume);
            setGrossAnualIncomeOptions(getBankVerificationQuestionsData?.gross_annual_income);
        };
    }, [coinsData, getBankVerificationQuestionsData]);

    return (
        <div className={styles.wrap}>
            {loading && <LoaderScreen />}
            <div className={styles.title}>As per Wwft Regulations in the Netherlands and Europe, please provide us the following information.</div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Dropdown
                        label="Where do you get your fiat (EURO) from?"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={fiat}
                        setValue={setFiat}
                        options={fiatOptions}
                    />
                </div>
                <div className={styles.col}>
                    <Dropdown
                        label="Why do you want to open an account?"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={account}
                        setValue={setAccount}
                        options={accountOptions}
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <Dropdown
                        label="In which coins will you trade the most?"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={coinText}
                        setValue={setCoinText}
                        options={coinsOptions}
                    />
                </div>
                <div className={styles.col}>
                    <Dropdown
                        label="Your average trading annual volume?"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={volume}
                        setValue={setVolume}
                        options={annualIncomeOptions}
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        className={styles.field}
                        classLabel={styles.label}
                        classInput={styles.input}
                        label="Your profession / occupation ?"
                        name="profession"
                        type="text"
                        required
                        placeholder="Enter your profession"
                        value={profession}
                        onChange={e => setProfession(e.target.value)}
                    />
                </div>
                <div className={styles.col}>
                    <Dropdown
                        label="Gross Annual Income?"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={annualIncome}
                        setValue={setAnualIncome}
                        options={grossAnnualIncomeOptions}
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <Dropdown
                    label="Are you expecting to transfer funds to any party outside of the Netherlands?"
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={fund}
                    setValue={setFund}
                    options={transferFund}
                />
            </div>
            <button className={cn("button", styles.button)} onClick={(e) => {
                e.preventDefault();
                if (fiat.toLowerCase() !== "select a value" && account.toLowerCase() !== "select a value" &&
                    coinText.toLowerCase() !== "select a value" && volume.toLowerCase() !== "select a value" &&
                    annualIncome.toLowerCase() !== "select a value" && profession !== "") {
                    handleSubmit();
                    questionsData(fiat, account, coinText, volume, annualIncome, profession, fund);
                }
                else {
                    addNotification({
                        title: "Alert",
                        message: "Please fill all the fields.",
                        type: "Danger"
                    });
                }
            }
            }>
                Continue
            </button>
        </div>
    )
};

export default SelectQuestions;
