import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Item from "./Item";
import NoDataFound from "../../../components/NoDataFound"

const Funds = ({ allAddress }) => {

  return (
    <div className={styles.wrap}>
      <h1 className={cn("h2", styles.title)}>
        My Referrals
      </h1>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>#</div>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Task completed</div>
        </div>
        {
          allAddress?.length > 0 ?
            allAddress?.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                key={index}
                index={index}
              />
            ))
            :
            <div className={styles.btns}>
              <NoDataFound className={styles.customButton} />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
