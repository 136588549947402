import React, { useEffect, useState } from "react";
import Bidding from "../../components/Bidding";
import SelectCurrency from "./SelectCurrency";
import ImportantNotes from "./ImportantNotes";
import PaymentDetails from "./PaymentDetails";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import LoaderScrren from "../../components/LoaderScreen";
import { useNavigate } from "react-router";
import { userStatusCreator } from "../../actions/getUserStatus";

const steps = ["Select currency", "Important notes", "Payment details"];

const DepositFiat = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [bankData, setBankData] = useState([]);
  const [sepaData, setSepaData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [absoluteFee, setAbsoluteFee] = useState("");
  const [relativeFee, setRelativeFee] = useState("");
  const [depositFeeData, setDepositFeeData] = useState([]);
  const [price, setPrice] = useState("0.00");

  // const [depositFiatData, setDepositFiatData] = useState([])
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }
    if (userStatus?.kyc_verification && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      navigate("/user-kyc-verification");
    } else if (userStatus?.kyc_verification && userStatus.bank_verification !== parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE)) {
      navigate("/bank-verification");
    }
  }, [])

  let data = {
    signature: localStorage.getItem("signature"),
  };

  const referenceNumberGenerator = () => {
    return 'EUPAY-' + Math.floor(100000 + Math.random() * 900000) + '' + Math.floor(100000 + Math.random() * 900000);
  };

  const userDataHandler = (amount, currency, bank, reference_code, bankText, gateWayID) => {
    setFormData({ amount, currency, bank, reference_code, bankText, gateWayID });
  };

  useEffect(() => {
    getUserPaymentMethods();
    // handleSepaPayment();
  }, []);

  const getUserPaymentMethods = async () => {
    setLoading(true)
    try {
      const paymentMethodPayload = await requestHandler("get_user_payment_methods", "post", data, "jwt_token");
      setBankData(paymentMethodPayload?.data?.data);
      setLoading(false)
    }
    catch (e) {
      setLoading(false)
      // addNotification({
      //   title: "Error",
      //   message: "Something went wrong.",
      //   type: "danger"
      // });
    }
  };

  const handleSepaPayment = async () => {
    setLoading(true);
    try {
      const gateWayListPayload = await requestHandler("gateway_list", "post", data, "jwt_token");
      gateWayListPayload?.data.data.map(x => {
        if (x.gateway_name.toLowerCase() === 'sepa') {
          setSepaData(x.services[0].issuers[0])
        }
      })
      // setSepaData(gateWayListPayload?.data?.data[0]?.services[0]?.issuers[0]);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    setLoading(true)
    let data = {
      signature: localStorage.getItem("signature"),
      amount: formData.amount,
      currency: formData.currency,
      type: 1,
      payment_mode: formData.bank,
      payment_reference: formData.reference_code,
      gateway_id: formData.gateWayID,
    };
    try {
      const newPaymentPayload = await requestHandler("new_payment", "post", data, "jwt_token");
      setLoading(false)
      if (newPaymentPayload.status === 200) {

        if (newPaymentPayload?.data?.data?._links) {
          window.open(newPaymentPayload?.data?.data?._links?.redirect?.href, "_self");
        }
        else {
          addNotification({
            title: "Error",
            message: 'Please Try Again',
            type: "danger"
          });
        }
      }

      else if (newPaymentPayload.status === 202) {
        // addNotification({
        //   title: "Success",
        //   message: newPaymentPayload?.data?.message[0]?.msg,
        //   type: "Success"
        // });
        // dispatch(userStatusCreator());
        // navigate("/fiat-deposit-list");
        setActiveIndex(2);
      };
    }
    catch (e) {
      setLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger"
      });
    };
  };

  const calculatePriceAfterFee = () => {
    if (relativeFee === undefined || relativeFee === null) {
      return price;
    }
    else {
      let deductedAmount = parseFloat(price) * (parseFloat(relativeFee) / 100);
      return (price - deductedAmount - absoluteFee) < 0 ? "0.00" : (parseFloat(price) - parseFloat(deductedAmount) - parseFloat(absoluteFee));
    }
  };

  const calculateTransactionFee = () => {
    let deductedAmount = (parseFloat(price) * (parseFloat(relativeFee) / 100));
    return price !== "0.00" ? parseFloat(deductedAmount) + parseFloat(absoluteFee) : "0.00"
  };

  const getUnconfirmedTransactionHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      payment_reference: formData.reference_code
    };
    try {
      const getUnconfirmedTransactionPayload = await requestHandler("update_unconfirm_status", "post", data, "jwt_token");
      setLoading(false);
      if (getUnconfirmedTransactionPayload && getUnconfirmedTransactionPayload.status === 202) {
        addNotification({
          title: "Success",
          message: getUnconfirmedTransactionPayload?.data?.message[0]?.msg,
          type: "Success"
        });
        dispatch(userStatusCreator());
        navigate("/fiat-deposit-list");
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Bidding title="Deposit Euro" items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && (
          <SelectCurrency
            goNext={() => setActiveIndex(1)}
            userDataHandler={userDataHandler}
            bankData={bankData}
            referenceNumberGenerator={referenceNumberGenerator}
            sepaData={sepaData}
            handleSepaPayment={handleSepaPayment}
            setLoading={setLoading}
            absoluteFee={absoluteFee}
            setAbsoluteFee={setAbsoluteFee}
            setRelativeFee={setRelativeFee}
            relativeFee={relativeFee}
            depositFeeData={depositFeeData}
            setDepositFeeData={setDepositFeeData}
            price={price}
            setPrice={setPrice}
            calculatePriceAfterFee={calculatePriceAfterFee}
            calculateTransactionFee={calculateTransactionFee}
          />
        )}
        {activeIndex === 1 && (
          <ImportantNotes
            formData={formData}
            goNext={() => setActiveIndex(2)}
            getUnconfirmedTransactionHandler={getUnconfirmedTransactionHandler}
            submitHandler={submitHandler}
          />
        )}
        {activeIndex === 2 &&
          <PaymentDetails
            depositFeeData={depositFeeData}
            formData={formData}
            submitHandler={submitHandler}
            sepaData={sepaData}
            relativeFee={relativeFee}
            absoluteFee={absoluteFee}
            price={price}
            setPrice={setPrice}
            calculatePriceAfterFee={calculatePriceAfterFee}
            calculateTransactionFee={calculateTransactionFee}
            getUnconfirmedTransactionHandler={getUnconfirmedTransactionHandler}
          />}
        {loading && <LoaderScrren />}
      </Bidding>
      {/* <Popular classSection="section-bg section-mb0" /> */}
    </>
  );
};

export default DepositFiat;
