import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { Link } from "react-router-dom";

const Successfully = ({ price }) => {
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Success!
        </div>
        <div className={styles.info}>
          Order Successful
        </div>
        <div className={styles.btns}>
          <Link className={cn("button-stroke", styles.button)} to="/exchange/BTC-EUR">
            Trade
          </Link>
          <Link className={cn("button", styles.button)} to="/wallet-overview">
            Wallet
          </Link>
        </div>
      </div>
    </>
  );
};

export default Successfully;
