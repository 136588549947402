import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = ({ item, closeModal, cancelOrder }) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Cancel!
        </div>
        <div className={styles.info}>
          Are you sure to cancel your {item.orderType === 3 || item.orderType === 1 ? <span style={{ color: "#58BD7D" }}>Buy</span> : <span style={{ color: "#FF6838" }}>Sell</span>} order
        </div>
        <div className={styles.info}>
          {item.buy === true ?
            <span>
              {/* {toFixedPrice(item.amount, item.currency)} {item.currency.toUpperCase()} */}
            </span>
            :
            <span>
              {/* {toFixedPrice(item.amount, item.coin)} {item.coin.toUpperCase()} */}
            </span>
          }

        </div>
        {/* <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {index === 1 ? `${item?.coin.toUpperCase()} - ${item?.currency.toUpperCase()}` : x.content}
              </div>
            </div>
          ))}
        </div> */}
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={closeModal}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={() => cancelOrder(item?.orderId)}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
