import React, { useEffect, useState } from "react";
import Main from "./Main";
import TokenInfo from "./TokenInfo";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import { addNotification } from "../../components/Notification";
import Funds from "./Funds";

const navigation = ["Open", "Redeemed"];

const Staking = () => {
  const [loading, setLoading] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  const [visibleStakeModal, setVisibleStakeModal] = useState(false);
  const [stakeAmount, setStakeAmount] = useState("");
  const [acknowledge, setAcknowledge] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const dispatch = useDispatch();
  const [stakingValues, setStakingValues] = useState([]);
  const [visibleRedeemModal, setVisibleRedeemModal] = useState(false);
  const [redeemingItem, setRedeemingItem] = useState();
  const [availableAmount, setAvailableAmount] = useState();
  const [stakeDetails, setStakeDetails] = useState({});
  const [visibleStakingDetails, setVisibleStakingDetails] = useState(false);
  const [visibleFundTransfer, setVisibleFundTransfer] = useState(false);
  const [activeTab, setActiveTab] = useState(navigation[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userStatusCreator());
    }
    else {
      getStakeValuesHandler();
      getUserStakingValues();
    }
  }, [userStatus]);

  const getStakeValuesHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const stakePayload = await requestHandler("get_stake_values", "post", data, "jwt_token");
      setStakeData(stakePayload?.data?.data);
      setLoading(false);

      let stakingToken = userStatus?.stake_wallet?.balances?.find(x => x?.symbol === "bdnx").balance;
      setAvailableAmount(stakingToken);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const addStakeAmountHandler = async (amount) => {
    setLoading(true);
    let data = {
      userId: userStatus?.id,
      stakeAmount: amount,
      signature: localStorage.getItem("signature"),
    };
    try {
      const addStakePayload = await requestHandler("stake_crypto_order", "post", data, "jwt_token");
      setLoading(false);
      if (addStakePayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addStakePayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleStakeModal(false);
        setStakeAmount("");
        setAcknowledge(false);
        setAgreement(false);
        dispatch(userStatusCreator());
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const getUserStakingValues = async () => {
    setLoading(true);
    let data = {
      userId: userStatus?.id,
      signature: localStorage.getItem("signature"),
    };
    try {
      const getUserStakingPayload = await requestHandler("get_stake_crypto_list", "post", data, "jwt_token");
      setLoading(false);
      setStakingValues(getUserStakingPayload?.data?.data?.modifiedStakeCryptoOrders);
      setStakeDetails(getUserStakingPayload?.data?.data?.stakeData);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const redeemStakeHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: id
    };
    try {
      const redeemStakePayload = await requestHandler("stake_crypto_redeem", "post", data, "jwt_token");
      setLoading(false);
      if (redeemStakePayload?.status === 200) {
        addNotification({
          title: "Success",
          message: redeemStakePayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleRedeemModal(false);
        getUserStakingValues();
        dispatch(userStatusCreator())
      }
    }
    catch (e) {
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger",
      });
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Main
        stakeData={stakeData}
      />
      <TokenInfo
        visibleStakeModal={visibleStakeModal}
        setVisibleStakeModal={setVisibleStakeModal}
        stakeAmount={stakeAmount}
        setStakeAmount={setStakeAmount}
        acknowledge={acknowledge}
        setAcknowledge={setAcknowledge}
        setAgreement={setAgreement}
        agreement={agreement}
        addStakeAmountHandler={addStakeAmountHandler}
        availableAmount={availableAmount}
        stakeDetails={stakeDetails}
        stakeData={stakeData}
        visibleFundTransfer={visibleFundTransfer}
        setVisibleFundTransfer={setVisibleFundTransfer}
      />
      <Funds
        stakingValues={stakingValues}
        redeemStakeHandler={redeemStakeHandler}
        visibleRedeemModal={visibleRedeemModal}
        setVisibleRedeemModal={setVisibleRedeemModal}
        redeemingItem={redeemingItem}
        setRedeemingItem={setRedeemingItem}
        setVisibleStakingDetails={setVisibleStakingDetails}
        visibleStakingDetails={visibleStakingDetails}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        navigation={navigation}
      />
    </>
  );
};

export default Staking;
