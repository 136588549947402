import styles from "./SelectQuestions.module.sass";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import TextInput from "../../../components/TextInput";
import { addNotification } from "../../../components/Notification";
import requestHandler from "../../../actions/httpClient";
import { userStatusCreator } from "../../../actions/getUserStatus";
import { useDispatch } from "react-redux";


const SelectQuestions = () => {
    const [zipCode, setZipcode] = useState('')
    const [address, setAddress] = useState('')
    const [appartment, setAppartment] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [adressProof, setAddressProof] = useState('')
    //   const [city, setCity] = useState(userStatus.city)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className={styles.wrap}>
            <div className={styles.title}>As per Wwft Regulations in the Netherlands and Europe, please provide us the following information.</div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="ADDRESS"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        // value={address == 'null' || address == null ? setAddress("") : address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeHolder="Enter address"
                    // disabled={buttonProperty}

                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="Appartment/suite"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        //value={appartment == 'null' || appartment == null ? setAppartment("") : appartment}
                        placeHolder="Enter appartment address"
                        onChange={(e) => setAppartment(e.target.value)}
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="City"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setCity(e.target.value)}
                        placeHolder="Enter your city"
                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="State"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setState(e.target.value)}
                        placeHolder="Enter you state"
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="Country"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setCountry(e.target.value)}
                        placeHolder="Enter your country"
                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="Zip/Postal code"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setZipcode(e.target.value)}
                        placeHolder="Enter zip code"
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <br />
                    <TextInput
                        className={styles.field}
                        label="Scan With Proof Of address"
                        name="addressProof"
                        type="file"
                        note="Use Screenshot of your address where it exists."
                        required
                        onChange={e => setAddressProof(e.target.files[0])}
                    />
                </div>
            </div>


            <br />

            <button className={cn("button", styles.button)} onClick={async (e) => {
                if (zipCode !== "" && address !== "" && appartment !== "" && country !== "" && state !== "" && city !== "" && adressProof !== "") {
                    e.preventDefault();
                    let userResident = {
                        address1: address,
                        address2: appartment,
                        city: city,
                        state: state,
                        country: country,
                        zip_code: zipCode,
                        proof: adressProof,
                        signature: localStorage.getItem("signature"),
                    }

                    try {
                        const payload = await requestHandler('verify-resident-address', 'post', userResident, 'jwt_token', 'file')
                        if (payload.status === 200) {
                            addNotification({
                                title: 'Success',
                                message: payload?.data?.message[0]?.msg,
                                type: 'Success'
                            });
                            dispatch(userStatusCreator())

                            navigate('/profile-info')

                        }
                    } catch (error) {

                    }
                } else {
                    addNotification({
                        title: "Alert",
                        message: "Please fill all the fields.",
                        type: "Danger"
                    });

                }
            }
            }>
                Continue
            </button>
        </div>
    )
};

export default SelectQuestions;
