import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h3>General</h3>
          <p>
            Although there are no regulations regarding Bitcoin and other digital coins or tokens at the moment, also called cryptocurrencies, Bitdenex follows European financial legislation as much as possible. This includes, among other things, the know your customer (KYC) policy and measures to prevent money laundering (AML).
          </p>
          <p>
            Regulations on financial services require Bitdenex to take measures against abuse. This regulation always has two sides. On the one hand protecting customers and on the other hand preventing financial violations (such as fraud and money laundering) through our services, including trading on the Exchange. In order to use our services, the customer must verify his/her identity at all times.
          </p>
          <p>
            The KYC policy consists of an extensive account verification process and a cooling down period for large transactions.
          </p>
          <h3>Account Verification</h3>
          <p>
            All coin/token providers and trading platforms must comply with the know your customer regulations (Wwft).
          </p>
          <h3>
            Step 1: Identity verification
          </h3>
          <p>
            As a new customer, you must verify your account before we can trade and/or send/receive Bitcoins, Ethereums, Litecoins and other cryptocurrency. This process is a one-time process and it takes only a few minutes. Through our contracted third party service, you can easily go through the identity verification process, including AML/sanction watchlists and adverse media checks, and upload a valid ID-card.
          </p>
          <h3>
            Step 2: Bank verification
          </h3>
          <p>
            After your identity verification (step 1), you can immediately make an iDeal payment/deposit or a payment through an alternative payment method and verify your bank account. We will receive this transfer immediately, if not within a few minutes, depending on your bank.
          </p>
          <p>
            With a bank account verification, we want to prevent any abuse of our services. We take and will take all measures in the future to prevent fraud and abuse. Unfortunately, this market is attractive to cybercriminals, and they are very creative to provide unsuspecting innocent people with their bank account information (phishing), inform people or hack their computer and rob bank accounts.
          </p>
          <h3>
            Step 3: Residence verification
          </h3>
          <p>
            After the bank verification (Step 2), you can verify your residence, by providing a proof of your address.
          </p>
          <figure>
            <img srcSet="/images/content/kyc/article.jpg" src="/images/content/kyc/article.jpg" alt="Content" />
          </figure>
          <h3>Cooling down</h3>
          <p>
            If you do a major (Bitcoin, Ethereum, Litecoin or other cryptocurrency) transaction, a cooling down period applies.
          </p>
          <p>
            <strong>What does this period mean?</strong> <br />
            It means we hold these digital coins for a certain period of time. This period depends on the size of the transaction. In the light of fraud, the bank or payment provider, and transaction monitoring provider will be given the opportunity to detect any fraud and report to us.
          </p>
          <p>
            Almost all transactions are performed automatically. This also means that almost all transactions are through automated fraud detection. Some are suspiciously marked because of their specific feature. This transaction is executed manually after some control elements or in case of fraud, appropriate measures will be taken.
          </p>
        </div>

      </div>
    </div>
  );
};

export default Article;
