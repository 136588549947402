import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Item = ({
    item,
    index,
    walletData,
    userStatus,
    withdrawHandler,
    depositHandler,
    activeTab,
    setVisibleTransfer
}) => {

    return (
        activeTab?.toLowerCase() === "spot" ?
            <div className={styles.row} key={index} >
                <div className={styles.col}>
                    <div className={styles.currency}>
                        <div className={styles.icon}>
                            <img src={walletData?.image_url + item.image} alt="Currency" />
                        </div>
                        <div className={styles.details}>
                            <div className={styles.info}>{item.symbol.toUpperCase()} <span>{item.name.toUpperCase()}</span></div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    {/* <div className={styles.info}>{twoDigitsAfterDecimal(item.balance, item.symbol)}</div> */}

                    <div className={styles.info}>{item?.symbol === "eur" || item?.symbol === "usdt" ? getDigitsAfterDecimal(item.balance, 3) : getDigitsAfterDecimal(item.balance, item?.decimalPrecision)}</div>
                </div>
                <div className={styles.col}>
                    {/* <div className={styles.info}>{getDigitsAfterDecimal(item.euro_balance, walletData.euro_decimal_price_precision)}</div> */}

                    <div className={styles.info}>{item?.symbol === "eur" || item?.symbol === "usdt" ? getDigitsAfterDecimal(item.euro_balance, 3) : getDigitsAfterDecimal(item.euro_balance, 3)}</div>
                </div>
                <div className={styles.col}>
                    {parseInt(item.is_eur_market) === 0 || parseInt(item.is_eur_market) === 2 ?
                        <>
                            {item.coinId !== parseInt(process.env.REACT_APP_EURO_ID) && <Link
                                className={cn("button-stroke button-small notDisabled", styles.button)}
                                to={userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) ? "/user-kyc-verification" :
                                    `/exchange/${item.symbol.toUpperCase() + '-USDT'}`}
                            >
                                Trade
                            </Link>}
                        </>
                        :
                        <>
                            <Link
                                className={cn("button-stroke button-small", styles.button)}
                                to={{
                                    pathname: userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) ? "/user-kyc-verification" :
                                        "/buy-crypto", state: { symbol: item.symbol.toUpperCase() + '-EUR' }
                                }}
                            >
                                Buy
                            </Link>
                            <Link
                                className={cn("button-stroke button-small", styles.button)}
                                to={{
                                    pathname: userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) ? "/user-kyc-verification" :
                                        "/sell-crypto", state: { symbol: item.symbol.toUpperCase() + '-EUR' }
                                }}
                            >
                                Sell
                            </Link>
                            <Link
                                className={cn("button-stroke button-small", styles.button)}
                                to={userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) ? "/user-kyc-verification" :
                                    `/exchange/${item.symbol.toUpperCase() + '-EUR'}`}
                            >
                                Trade
                            </Link>
                        </>
                    }
                </div>
                <div className={styles.col}>
                    <button disabled={(item?.enable_deposit === parseInt(process.env.REACT_APP_DEPOSIT_DISABLE))} className={cn("button-stroke button-small", styles.button)} onClick={() => depositHandler(item, walletData.image_url)} >
                        Deposit
                    </button>
                    <button disabled={(item?.enable_withdraw === parseInt(process.env.REACT_APP_WITHDRAW_DISABLE))} className={cn("button-stroke button-small", styles.button)} onClick={() => withdrawHandler(item, walletData.image_url)} >
                        Withdraw
                    </button>
                </div>
            </div>
            :
            <div className={styles.row} key={index} >
                <div className={styles.col}>
                    <div className={styles.currency}>
                        <div className={styles.icon}>
                            <img src={walletData?.image_url + item.image} alt="Currency" />
                        </div>
                        <div className={styles.details}>
                            <div className={styles.info}>{item.symbol.toUpperCase()} <span>{item.name.toUpperCase()}</span></div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.info}>{item?.symbol === "eur" || item?.symbol === "usdt" ? getDigitsAfterDecimal(item.balance, 3) : getDigitsAfterDecimal(item.balance, item?.decimalPrecision)}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.info}>{item?.symbol === "eur" || item?.symbol === "usdt" ? getDigitsAfterDecimal(item.euro_balance, 3) : getDigitsAfterDecimal(item.euro_balance, 3)}</div>
                </div>
                <div className={styles.col}>
                    <button
                        className={cn("button-stroke button-small", styles.button)}
                        onClick={() => { setVisibleTransfer(true); }}
                    >
                        Transfer
                    </button>
                </div>
            </div>
    );
};

export default Item;



