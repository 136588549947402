import React from "react";
import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import TradeSkeleton from "../../../components/Skeleton/TradeSkeleton";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";

const Funds = ({ children, getWalletWithdrawListData, handleLoadMore, pageno, loading }) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>

        <button className={styles.link}>
          <span>Withdrawals Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Coin</div>
          <div className={styles.col}>Transaction Id</div>
          <div className={styles.col}>Address</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {loading ? <WalletSkeleton rowCount={10} colCount={6} /> :

          (getWalletWithdrawListData?.length > 0 ?
            <>
              {getWalletWithdrawListData?.map((x, index) => (
                <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  children={children}
                />
              ))}
              {getWalletWithdrawListData?.length === (10 * pageno) && <div className={styles.customButton}>
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                  <span>Load more...</span>
                </button>
              </div>
              }
            </>
            :
            <div className={styles.btns}>
              <NoDataFound />
            </div>)
        }


      </div>
    </div>
  );
};

export default Funds;
