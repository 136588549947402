import requestHandler from '../httpClient'
import {
    GET_USER_STATUS_REQUEST, GET_USER_STATUS_SUCCESS, GET_USER_STATUS_FAILED
} from './actionType'


const getUserStatusRequest = () => {
    return {
        type: GET_USER_STATUS_REQUEST
    }
}





const getUserStatusSuccess = (payload) => {
    return {
        type: GET_USER_STATUS_SUCCESS,
        payload: payload
    }
}



const getUserStatusFailed = (error) => {
    return {
        type: GET_USER_STATUS_FAILED,
        error: error
    }
}



const userStatusCreator = () => {

    return async (dispatch) => {
        dispatch(getUserStatusRequest())

        try {

            let data = {
                signature: localStorage.getItem("signature"),
            };
            const payload = await requestHandler('getCustomerVerifictionStatus', 'post', data, 'jwt_token')
            dispatch(getUserStatusSuccess(payload))

        } catch (error) {
            dispatch(getUserStatusFailed(error))

        }


    }

}



export {
    userStatusCreator
}