import React, { useEffect, useState } from "react";
import Bidding from "../../components/Bidding";
import SelectCurrency from "./SelectCurrency";
import ImportantNotes from "./ImportantNotes";
import PaymentDetails from "./PaymentDetails";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from '../../actions/httpClient'
import { addNotification } from "../../components/Notification";
import { useNavigate } from "react-router";
import { userStatusCreator } from "../../actions/getUserStatus";
const steps = ["Select currency", "Payment details"];

const WithdrawFiat = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const [selectedPrice, setSelectedPrice] = useState()
  const [currency, setCurrency] = useState()
  const navigate = useNavigate();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const [eurData, setEurData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator());
    }
    userStatus?.wallet?.balances.filter((x) => {
      if (x?.symbol === "eur") {
        setEurData(x);
      }
    })
  }, []);

  const priceHandler = (price, currency) => {
    setSelectedPrice(price);
    setCurrency(currency)
  }

  const withdrawFiatHandler = async (price, currency) => {
    setLoading(true);
    let data = {
      amount: price,
      currency: currency,
      signature: localStorage.getItem('signature')
    }
    try {
      let response = await requestHandler('/new_withdraw_request', 'post', data, 'jwt_token')
      setLoading(false);
      if (response.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: 'Success',
          message: 'Withdraw Request Recieved',
          type: 'success'
        })
      }
      navigate('/fiat-withdraw-list')
    } catch (error) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: error?.data?.message[0]?.msg,
        type: 'danger'
      })
    }
  }

  return (
    <>
      <Bidding title="Withdraw Euro" items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && (
          <SelectCurrency
            goNext={() => setActiveIndex(1)}
            priceHandler={priceHandler}
            eurData={eurData}
            loading={loading}
          />
        )}
        {activeIndex === 1 && (
          <PaymentDetails
            // linkData={depositFiatData?._links?.redirect?.href}
            selectedPrice={selectedPrice}
            currency={currency}
            withdrawFiatHandler={withdrawFiatHandler}
            loading={loading}
          // goNext={() => setActiveIndex(2)}
          />
        )}
        {/* {activeIndex === 2 && <PaymentDetails
          selectedPrice={selectedPrice}
          currency={currency}
          withdrawFiatHandler={withdrawFiatHandler}
        />} */}
      </Bidding>
      {/* <Popular classSection="section-bg section-mb0" /> */}
    </>
  );
};

export default WithdrawFiat;
