import React, { useEffect, useState } from "react";
import styles from "./ProfileInfo.module.sass";
import Profile from "../../components/Profile";
import cn from "classnames";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";
import Coupons from "./Coupons";
import CustomDropdown from "../../components/CustomDropdown";
import NoDataFound from "../../components/NoDataFound";
import Modal from "../../components/Modal";
import Confirmation from "./Coupons/Confirmation"
import { addNotification } from "../../components/Notification";

const breadcrumbs = [
  {
    title: "Coupon & Rewards",
    url: "/",
  },
  {
    title: "Coupon & Rewards",
  },
];

var statusOptions = [
  { cId: parseInt(process.env.REACT_APP_COUPON_ACTIVATION_COMPLETED), cValue: "Collected" },
  { cId: parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING), cValue: "Pending" },
  { cId: parseInt(process.env.REACT_APP_COUPON_ACTIVATION_EXPIRED), cValue: "Expired" },
];

const ProfileInfo = () => {
  const [loading, setLoading] = useState();
  const [couponData, setCouponData] = useState([]);
  const [statusText, setStatusText] = useState(statusOptions[0].cValue);
  const [statusValue, setStatusValue] = useState(statusOptions[0].cId);
  const [visibleModal, setVisibleModal] = useState(false);
  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    getCouponRewardsHandler();
  }, [statusValue]);

  const getCouponRewardsHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      type: statusValue
    };
    try {
      const getCouponRewardsPayload = await requestHandler("getCoupon", "post", data, "jwt_token");
      setLoading(false);
      setCouponData(getCouponRewardsPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const activateCouponHandler = async (id, coupon_id, region) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      coupon_id: coupon_id,
      id: id,
      region: region
    };
    try {
      const activateCouponPayload = await requestHandler("activateCoupon", "post", data, "jwt_token");
      setLoading(false);
      if (activateCouponPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: activateCouponPayload?.data?.message[0]?.msg,
          type: 'success'
        });
        setVisibleModal(false);
        getCouponRewardsHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  return (
    <Profile title="Coupon & Rewards" breadcrumbs={breadcrumbs}>
      {loading && <LoaderScreen />}
      <div className={styles.settings}>
        <div className={styles.box} >
          <div className={styles.title}>Coupon & Rewards</div>
          <div className={styles.flexParentElement}>
            <div className={styles.flexChildButton}>
              <CustomDropdown
                className={styles.dropdown}
                label=""
                value={statusValue}
                setValue={setStatusValue}
                text={statusText}
                setText={setStatusText}
                options={statusOptions}
              />
            </div>
          </div>
          <div className={styles.table}>
            {/* <div className={styles.row}>
              <div className={styles.col}>#</div>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Amount</div>
              {statusValue !== parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) && <div className={styles.col}>Pending</div>}
              {statusValue !== parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) && <div className={styles.col}>Region</div>}
              <div className={styles.col}>Validity</div>
              {statusValue === parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) && <div className={styles.col}>Action</div>}
            </div> */}
            {
              couponData?.length > 0 ?
                couponData?.map((x, index) => (
                  <Coupons
                    className={styles.item}
                    item={x}
                    key={index}
                    index={index}
                    statusValue={statusValue}
                    setVisibleModal={setVisibleModal}
                    setItemData={setItemData}
                  />
                ))
                :
                <div className={styles.btns}>
                  <NoDataFound className={styles.customButton} />
                </div>}
          </div>
        </div>
      </div>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Confirmation
          itemData={itemData}
          activateCouponHandler={activateCouponHandler}
        />
      </Modal>
    </Profile>
  );
};

export default ProfileInfo;
