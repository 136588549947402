import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";
import BitdenexPayTransfer from "../../../components/BitdenexPayTransfer";
import { useState } from "react";
import Modal from "../../../components/Modal";

const Funds = ({ children, transactionListData, loading, pageRefreshFlag, setPageRefreshFlag }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.line}>
          <button className={styles.link}>
            <span>Bitdenex Pay Transactions</span>
            <Icon name="arrow-right" size="24" />
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisibleModal(!visibleModal)}
          >
            {process.env.REACT_APP_BITDENEX_PAY_TEXT}
          </button>
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <WalletSkeleton rowCount={10} colCount={6} /> :
            transactionListData?.length > 0 ?
              <>
                {
                  transactionListData?.map((x, index) => (
                    <Item
                      className={styles.item}
                      item={x}
                      key={index}
                      children={children}
                    />
                  ))
                }
              </>
              :
              <div className={styles.btns}>
                <NoDataFound />
              </div>
          }
        </div>
      </div>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <BitdenexPayTransfer
          setVisibleModal={setVisibleModal}
          pageRefreshFlag={pageRefreshFlag}
          setPageRefreshFlag={setPageRefreshFlag}
        />
      </Modal>
    </>
  );
};

export default Funds;
