import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = ({ itemData, activateCouponHandler }) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Confirm?
        </div>
        <div className={styles.info}>
          On which exchange, do you want to activate this coupon?
        </div>
        <div className={styles.info}>
          <button
            className={cn("button-small", styles.button)}
            onClick={() => { activateCouponHandler(itemData?.id, itemData?.coupon_id, process.env.REACT_APP_EUROPE_MARKETS) }}
          >
            Europe
          </button>
          <button
            className={cn("button-small", styles.button)}
            onClick={() => { activateCouponHandler(itemData?.id, itemData?.coupon_id, process.env.REACT_APP_INTERNATIONAL_MARKETS) }}
          >
            International
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
