import React, { useEffect, useState } from "react";
import Profile from "../../components/Profile";
import StepOne from './StepOne'
import SelectQues from './SelectQuestions'
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
// import { useDispatch, useSelector } from "react-redux";
// import { userAuthCreator } from "../../actions";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Resident Verification",
  },
];

const ResidentVerification = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const dispatch = useDispatch()

  const { userStatus } = useSelector((state) => { return state.getUserStatus });



  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }

  }, [])

  return (
    <Profile title="Resident Verification" breadcrumbs={breadcrumbs}>
      {activeIndex === 0 && <StepOne userStatus={userStatus} goNext={() => setActiveIndex(1)} />}
      {activeIndex === 1 && (<SelectQues userStatus={userStatus} />)}
    </Profile>
  );
};

export default ResidentVerification;
