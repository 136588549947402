import cn from "classnames";
import styles from "./Main.module.sass";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "../../../components/Modal"
import BitdenexPayTransfer from "../../../components/BitdenexPayTransfer";

const Main = () => {

  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      {/* <div className={cn("container", styles.headContainer)}>
        <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)} >
          Send
        </button>
      </div> */}
      <div className={cn("section-mb0", styles.main)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>
              Pay and get paid with crypto
            </div>
            <h3 className={cn("h3", styles.title)}> Welcome to <span> Bitdenex Pay </span> </h3>
            <div className={styles.text}>
              Bitdenex Pay is a contactless, borderless and secure cryptocurrency payment technology designed by Bitdenex. Shop with crypto or send crypto to friends and family worldwide.
            </div>
            <Link className={cn("button", styles.button)} to="/" >
              Download Now
            </Link>
          </div>
          <div className={styles.bg}>
            <img
              srcSet="/images/content/bitdenex-pay@2x.png"
              src="/images/content/bitdenex-pay.png"
              alt="Cards"
            />
          </div>
        </div>
      </div>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <BitdenexPayTransfer
          setVisibleModal={setVisibleModal}
        />
      </Modal>
    </>
  );
};

export default Main;
